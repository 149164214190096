
import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';

export default class DeliveryNoteChangeHistory{
    constructor(){
        this.changeHistoryContainer = document.querySelector('#change-history-container-delivery-note');
        if(!this.changeHistoryContainer) return;
        this.event();  
    }
    event(){
        if (this.changeHistoryContainer) {
            const modelName = 'deliverynote';
            const objectId = deliveryNoteIdVar;
            const fieldsToShow = ['designation_number', 'status', 'amount'];
            const fieldLabels = {
                designation_number: 'Bezeichnungsnummer',
                status: 'Status',
                amount: 'Betrag'
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-delivery-note', fieldsToShow, fieldLabels);
        }
    }
}