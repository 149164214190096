import AlertHandler from '../../../../assets/js/modules/alertHandler'
import customersChangeHistory from './customersChangeHistory'

export default class customersUpdate{
    constructor(){
        this.events();
    }

    events(){
        const customersUpdate = document.querySelector('#customerUpdate');
        if(customersUpdate){
            customersUpdate.addEventListener('submit', this.customersUpdate.bind(this));
        }
    }

    customersUpdate(event){
        console.log('RUN');
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => {
            return response.text();
        })
        .then(text => {
            try {
                const data = JSON.parse(text);
                if (data.success) {
                    AlertHandler.showAlert('Update erfolgreich', 'success');

                    setTimeout(() => {
                        const history = new customersChangeHistory();
                        history.event();
                    }, 300);

                } else {
                    AlertHandler.showAlert('Fehler: ' + errorMessage, 'danger');
                }
            } catch (parseError) {
                AlertHandler.showAlert('Fehler beim Parsen der Antwort: ' + text, 'danger');
            }
        })
        .catch(error => {
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error.message, 'danger');
        });

    }


}