import { Modal } from 'bootstrap';
import GenericAPI from '../../../../assets/js/modules/GenericAPI';
import AlertHandler from '../../../../assets/js/modules/alertHandler';
import RenderNotes from './renderNotes';

const observeDOMChanges = () => {
    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === 1) {
                        const editModals = node.querySelectorAll('.edit-modal-notes');
                        editModals.forEach(setupEditModal);
                    }
                });
            }
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

const setupEditModal = (modal) => {

    modal.addEventListener('click', async () => {

        const modalElement = document.getElementById('genericModal');
        if (!modalElement) {
            console.error('Modal element not found');
            return;
        }

        const url = modal.getAttribute('data-url');
        const api = new GenericAPI(url);
        const data = await api.loadData();

        modalElement.querySelector('.modal-content').innerHTML = data.html;

        const bootstrapModal = new Modal(modalElement);
        bootstrapModal.show();

        const form = modalElement.querySelector('form');
        if (form) {
            form.setAttribute('action', url);
            form.addEventListener('submit', (event) => {
                event.preventDefault();
                updateNotes(form, modalElement);
            });
        }

    });
}

const updateNotes = async (form, modalElement) => {
    const formData = new FormData(form);

    try {
        const response = await fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': form.querySelector('[name=csrfmiddlewaretoken]').value
            }
        });
        
        const data = await response.json();
        if (data.success) {
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
            const notes = new RenderNotes;
            notes.init();
            const modal = Modal.getInstance(modalElement);
            modal.hide();
        } else {
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
        }

    } catch (error) {
        console.error('Failed to submit form:', error);
    }
}

observeDOMChanges();

