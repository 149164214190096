

import AlertHandler from "../../../../assets/js/modules/alertHandler";

export default class deaktiviertAccounts{
    constructor(){
        this.deaktivierenBtn = document.getElementById('accountsDeaktivierenBtn');
        if(!this.deaktivierenBtn) return;
        this.events();
    }

    events(){
        this.deaktivierenBtn.addEventListener('click', () => this.handleDeaktivieren());
    }

    handleDeaktivieren() {
        const url = this.deaktivierenBtn.dataset.url;
        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]').value,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                AlertHandler.showAlert(data.message, 'success');
                this.updateButton(data.is_active);
            } else {
                console.error(data.message);
                AlertHandler.showAlert(data.message, 'error');
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
    }

    updateButton(isActive) {
        if (isActive) {
            this.deaktivierenBtn.classList.remove('btn-success');
            this.deaktivierenBtn.classList.add('btn-danger');
            this.deaktivierenBtn.textContent = 'Benutzer deaktivieren';
        } else {
            this.deaktivierenBtn.classList.remove('btn-danger');
            this.deaktivierenBtn.classList.add('btn-success');
            this.deaktivierenBtn.textContent = 'Benutzer aktivieren';
        }
    }

}