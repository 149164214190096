

import servicesCreate from './module/serviceCreate'
import serviceUpdate from './module/serviceUpdate'
import serviceChangeHistory from './module/serviceChangeHistory';
import getNextNumerView from '../../../core/static/module/numberRange';
import ArchiveManager from '../../../assets/js/common/ArchiveModal';
import ActivitiesList from '../../../activities/static/js/module/ActivitiesList';
import checkReferences from '../../../assets/js/common/CheckReferences';

document.addEventListener('DOMContentLoaded', function () {
    const servicecreate = new servicesCreate();
    const serviceupdate = new serviceUpdate();
    const servicechangehistory = new serviceChangeHistory();

    const archiveBtn = document.getElementById('archiveBtn');
    if(!archiveBtn) return;
    const archiveManager = new ArchiveManager(archiveBtn);
    archiveBtn.addEventListener('click', () => {
        const url = archiveBtn.getAttribute('data-archive'); 
        archiveManager.toggleArchive(url)
    });

    if(document.getElementById('activities-service-container')){
        const activitieslist = new ActivitiesList(activitiesList, 'activities-service-container');
        window.reloadActivitiesList = function () {
            if (typeof activitieslist !== 'undefined' && activitieslist) {
                activitieslist.init();
            }
        };
    }
    
    if(document.querySelector('[data-permission="delete_services"]')){
        checkReferences('services', 'Service', serviceId);
    }

    // Archivieren
    const serviceCreateForm = document.getElementById('serviceCreateForm');
    if(!serviceCreateForm){
        return
    }else{
        getNextNumerView('SER');
    }


}); 