

import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class UpdateOrders{
 
    constructor(){
        this.orderCreateForm = document.querySelector('#ordersUpdateForm');
        if (!this.orderCreateForm) return;
        this.events();
    }

    events(){
        let timer;
        const saveForm = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                BaseAjaxSave({
                    target: this.orderCreateForm,
                    preventDefault: () => {}
                });
            }, 300);
        };
        this.orderCreateForm.addEventListener('change', saveForm);
        $(this.orderCreateForm).find('#id_customer').on('select2:select', saveForm);

    }
    
}
