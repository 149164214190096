
import GpsRoute from './GpsRoute';
import GpsRouteView from './GpsRouteView';

document.addEventListener('DOMContentLoaded', function () {

    const mapRouteView = document.getElementById('mapRouteView');
    if (mapRouteView && typeof objectId !== 'undefined') {
        const gpsrouteview = new GpsRouteView();
        gpsrouteview.init(objectId);
    }

    const dataContainer = document.querySelector('#dataContainerOrder');
    if(!dataContainer) return
    
    // Fügt Standort-Icon zu Objektzeilen nach Tabellenladen hinzu.
    function observeElementOrder(selector, callback) {
        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                callback();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    observeElementOrder('#calculation-tbody', () => {
        document.querySelectorAll('tr[data-type="object"]').forEach(modifyObjectRow);
    });


    function modifyObjectRow(row) {
        const firstTd = row.querySelector('td');
        const deleteDiv = firstTd.querySelector('.delete-object');
        const id = deleteDiv ? deleteDiv.getAttribute('data-id') : null;
    
        if (id) {
            pruefeRoute(id, firstTd);
        }
    }

    function pruefeRoute(id, firstTd) {
        const detailRouteUrl = DetailRoute.replace('/0/', `/${id}/`);
    
        fetch(detailRouteUrl)
            .then(response => response.json())
            .then(data => {
                const divContainer = firstTd.querySelector('.d-flex');
                const existingIcon = divContainer.querySelector(`.gpsModal[data-id="${id}"]`);
                if (data.length > 0) {
                    if (existingIcon) {
                        existingIcon.remove();
                    }
                    const route = data[0];
                    const existingCell = firstTd.closest('tr').querySelector('td[colspan="8"]');
                    if (existingCell) {
                        const routeInfo = document.createElement('span');
                        routeInfo.innerHTML = ` Route: ${route.name} `;
                        existingCell.appendChild(routeInfo);
                        
                        const detailButton = document.createElement('button');
                        detailButton.textContent = 'Route Starten';
                        detailButton.classList.add('btn', 'btn-primary', 'btn-xs', 'btn-sm', 'me-2');
                        detailButton.onclick = () => startTracking(route.id);
                        existingCell.appendChild(detailButton);

                        const editButton = document.createElement('button');
                        editButton.textContent = 'Route Stopen';
                        editButton.classList.add('btn', 'btn-sm', 'btn-xs', 'btn-secondary');
                        editButton.onclick = () => stopTracking(route.id);
                        existingCell.appendChild(editButton);

                        const newDiv = document.createElement('div');
                        newDiv.style.cursor = 'pointer';
                        const icon = document.createElement('i');
                        icon.classList.add('fa-solid', 'fa-route');
                        newDiv.appendChild(icon);
                        divContainer.appendChild(newDiv);
                        const templateRoute = detailTemplateRoute.replace('/0/', `/${id}/`);
                        newDiv.setAttribute('data-detail-route', templateRoute); 

                        newDiv.addEventListener('click', function() {
                            const detailRouteUrl = templateRoute.replace('/0/', `/${id}/`);
                            window.location.href = detailRouteUrl;
                        });
                    }
                } else {
                    if (!existingIcon) {
                        const newDiv = document.createElement('div');
                        newDiv.classList.add('gpsModal');
                        newDiv.setAttribute('data-id', id);
                        newDiv.setAttribute('data-create-route', CreateRoute);

                        const icon = document.createElement('i');
                        icon.classList.add('fa-solid', 'fa-location-dot');
                        newDiv.appendChild(icon);
                        divContainer.appendChild(newDiv);

                        const gpsroute = new GpsRoute();
                    }
                }
            })
            .catch(error => console.error('Fehler beim Prüfen der Route:', error));
    }


    let socket = null;
    let watchID = null;
    let trackingActive = false;
    let sendInterval = null;
    let lastPosition = null;
    let wakeLock = null;
    const statusElement = document.getElementById('status');

    async function requestWakeLock() {
        try {
            wakeLock = await navigator.wakeLock.request('screen');
            statusElement.textContent = 'Tracking aktiv. Handy bleibt wach.';
            console.log('Wake Lock aktiviert.');
            wakeLock.addEventListener('release', () => {
                console.log('Wake Lock freigegeben.');
            });
        } catch (err) {
            console.error(`${err.name}, ${err.message}`);
        }
    }

    async function releaseWakeLock() {
        if (wakeLock !== null) {
            await wakeLock.release();
            wakeLock = null;
            console.log('Wake Lock wurde manuell freigegeben.');
        }
    }

    const config = {
        local: {
            websocketURL: 'ws://127.0.0.1:49999/ws/track/'
        },
        live: {
            websocketURL: 'wss://agriflow.pixel-west.com/ws/track/'
        }
    };
    
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const websocketURL = isLocal ? config.local.websocketURL : config.live.websocketURL;

    function startTracking(id) {
        requestWakeLock();
        if (socket !== null) {
            console.log('WebSocket bereits verbunden.');
            return;
        }

        socket = new WebSocket(websocketURL);
        socket.onopen = function() {
            console.log('WebSocket verbunden.');
            trackingActive = true;
            window.onbeforeunload = preventPageLeave;

            if ('geolocation' in navigator) {
                watchID = navigator.geolocation.watchPosition(function(position) {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    if (positionHasChanged(latitude, longitude)) {                        
                        if (!lastPosition || positionHasChanged(latitude, longitude)) {
                            console.log('Neue GPS-Position:', latitude, longitude);
                            lastPosition = { latitude, longitude };
                            if (socket.readyState === WebSocket.OPEN) {
                                socket.send(JSON.stringify({
                                    'route_id': id,
                                    'latitude': latitude,
                                    'longitude': longitude
                                }));
                                console.log('GPS-Daten gesendet:', latitude, longitude);
                            }
                        }
                    }
                }, function(error) {}, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            }
        };
    
        socket.onmessage = function(event) {
            const data = JSON.parse(event.data);
            console.log('Empfangene GPS-Daten:', data.latitude, data.longitude);
        };
    
        socket.onerror = function() {
            console.error('WebSocket Fehler.');
        };
    }

    
    function stopTracking() {
        releaseWakeLock();
        console.log('Tracking stoppen');
        
        if (watchID !== null) {
            navigator.geolocation.clearWatch(watchID);
            watchID = null;
        }
        
        if (sendInterval !== null) {
            clearInterval(sendInterval);
            sendInterval = null;
        }
    
        if (socket !== null) {
            if (socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
            socket = null;
        }
    
        trackingActive = false;
        window.onbeforeunload = null; 
        lastPosition = null;
        statusElement.textContent = '';
    }

    function preventPageLeave(event) {
        if (trackingActive) {
            event.preventDefault();
            event.returnValue = '';
            return ''; 
        }
    }

    function positionHasChanged(newLat, newLon) {
        if (!lastPosition) return true;
        const threshold = 0.0001;
        return Math.abs(newLat - lastPosition.latitude) > threshold ||
               Math.abs(newLon - lastPosition.longitude) > threshold;
    }



    function updateObjectRows() {
        document.querySelectorAll('tr[data-type="object"]').forEach(modifyObjectRow);
    }


    if (window.reloadPositionsList) {
        const originalReloadFunction = window.reloadPositionsList;
        window.reloadPositionsList = function() {
            originalReloadFunction();
            setTimeout(updateObjectRows, 500);
        };
    }

});