

import GenericAPI from '../../../../assets/js/modules/GenericAPI';


export default class RenderPricelist{
    constructor(){
        this.init();
    }

    async init(){
        const conatiner = document.getElementById('CustomerPricelist');
        if(!conatiner) return;

        var url = PriceList.replace(/0\//, customersId + '/');
        const api = new GenericAPI(url);
        const data = await api.loadData();
        this.displayData(data);
    }

    formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    displayData(data){
        const container = document.getElementById('CustomerPricelist'); 
        if (!container) return;

        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';
        const table = document.createElement('table');
        table.className = 'table';
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');

        const headerRow = document.createElement('tr'); 
        const header = [
            'Aktion',
            'Bezeichnungsnummer',
            'Bezeichnung',
            'Kosten pro Einheit',
            'Steuer',
            'Stücke',
            'Einheit',
            'Gültig bis'
        ];

        header.forEach(element => {
            const th = document.createElement('th');
            th.textContent = element;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);

        if (Array.isArray(data.price_services)) {
            data.price_services.forEach(service => {
                const row = document.createElement('tr');
                var updateUrl = PriceServiceUpdate.replace(/0/, service.id);
                var deleteUrl = PriceServiceDelete.replace(/0/, service.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="openModalServie" data-modal-url="${updateUrl}">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div class="delete-modal" data-url="${deleteUrl}">
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${service.designation_number || ''}</td>
                    <td colspan="1">${service.designation || ''}</td>
                    <td colspan="1">${service.cost_per_unit || ''}</td>
                    <td colspan="1">${service.tax || ''}</td>
                    <td colspan="1">${service.pieces || ''}</td>
                    <td colspan="1">${service.unit || ''}</td>
                    <td colspan="1">${this.formatDate(service.valid_until)}</td>
                `;
                tbody.appendChild(row);
            });
        }
    
        if (Array.isArray(data.price_objects)) {
            data.price_objects.forEach(object => {
                const row = document.createElement('tr');
                var updateUrl = PriceObjectUpdate.replace(/0/, object.id);
                var deleteUrl = PriceObjectDelete.replace(/0/, object.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="openModalServie" data-modal-url="${updateUrl}">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div class="delete-modal" data-url="${deleteUrl}">
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">${object.designation_number || ''}</td>
                    <td colspan="4">${object.designation || ''}</td>
                    <td colspan="1">${this.formatDate(object.valid_until)}</td>
                `;
                tbody.appendChild(row);
            });
        }
    
        if (Array.isArray(data.price_texts)) {
            data.price_texts.forEach(text => {
                const row = document.createElement('tr');
                var updateUrl = PriceTextUpdate.replace(/0/, text.id);
                var deleteUrl = PriceTextDelete.replace(/0/, text.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="openModalServie" data-modal-url="${updateUrl}">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div class="delete-modal" data-url="${deleteUrl}">
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">${text.text || ''}</td>
                    <td colspan="1">${this.formatDate(text.valid_until)}</td>
                `;
                tbody.appendChild(row);
            });
        }
    
        if (Array.isArray(data.price_machinerys)) {
            data.price_machinerys.forEach(machinery => {
                const row = document.createElement('tr');
                var updateUrl = PriceMachinerysUpdate.replace(/0/, machinery.id);
                var deleteUrl = PriceMachinerysDelete.replace(/0/, machinery.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="openModalServie" data-modal-url="${updateUrl}">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div class="delete-modal" data-url="${deleteUrl}">
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${machinery.designation_number || ''}</td>
                    <td colspan="1">${machinery.designation || ''}</td>
                    <td colspan="1">${machinery.cost_per_unit || ''}</td>
                    <td colspan="1">${machinery.tax || ''}</td>
                    <td colspan="1">${machinery.pieces || ''}</td>
                    <td colspan="1">${machinery.unit || ''}</td>
                    <td colspan="1">${this.formatDate(machinery.valid_until)}</td>
                `;
                tbody.appendChild(row);
            });
        }


        table.appendChild(thead);
        table.appendChild(tbody);
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);
    }

}