

export default async function activitiesUser(){
    const activitiesListUser = document.querySelector('#activitiesListUser');
    if(!activitiesListUser) return;

    try {
        const url = '/activities/list/'
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.activiti_data) {
            renderActivitiesUser(data.activiti_data, activitiesListUser)
        } 
    } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
    }
}

function renderActivitiesUser(data, element){
    console.log(data)
    const tableBody = element.querySelector('tbody');
    const tableRows = data.map(activity => `
        <tr>
            <td>
                ${activity.content_type_url ? 
                    `<a href="${activity.content_type_url}" class="text-decoration-none">
                        ${activity.content_type_name}
                    </a>` : 
                    activity.id
                }
            </td>
            <td>
                ${activity.title}
            </td>
            <td>${activity.due_date || '-'}</td>
            <td>
                ${activity.date_completed ? 
                    '<span class="badge bg-success">Erledigt</span>' : 
                    '<span class="badge bg-warning">Offen</span>'}
            </td>
        </tr>
    `).join('');

    const tableHead = element.querySelector('thead');
    tableHead.innerHTML = `
        <tr>
            <th scope="col">ID</th>
            <th scope="col">Aufgabe</th>
            <th scope="col">Fällig am</th>
            <th scope="col">Status</th>
        </tr>
    `;

    tableBody.innerHTML = tableRows;
}