
export default class rendePricelistModal{
    constructor() {
        this.idCostumer = null;
        this.elemnetId = null;
        this.options = null;
        this.fetchedDatas = null;
    }

    async init(id, options) {
        this.idCostumer = id;
        this.options = options;
        var url = PriceList.replace(/0\//, id + '/');
        const response = await fetch(url);
        const data = await response.json();
        this.fetchedData = data;
        this.displayData();
    }

    displayData() {
        if (!this.fetchedData) {
            return;
        }
        if (this.options === 'service' && (!this.fetchedData.price_services || this.fetchedData.price_services.length === 0)) {
            document.getElementById('pricelistServiceModalForm')?.remove();
            return;
        } else if (this.options === 'object' && (!this.fetchedData.price_objects || this.fetchedData.price_objects.length === 0)) {
            document.getElementById('pricelistObjectModalForm')?.remove();
            return;
        } else if (this.options === 'machinerys' && (!this.fetchedData.price_machinerys || this.fetchedData.price_machinerys.length === 0)) {
            document.getElementById('pricelistMachinerysModalForm')?.remove();
            return;
        } else if (this.options === 'text' && (!this.fetchedData.price_texts || this.fetchedData.price_texts.length === 0)) {
            document.getElementById('pricelistTextModalForm')?.remove();
            return;
        }
        if (this.options === 'service') {
            this.showServiceData();
        } else if (this.options === 'object') {
            this.showObjectData();
        } else if (this.options === 'machinerys') {
            this.showMachineryData();
        } else if (this.options === 'text') {
            this.showTextyData();
        }
    }

    formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }


    showServiceData() {
        const container = document.getElementById('pricelistServiceModalForm')
        if (!container) return;
        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';
        const table = document.createElement('table');
        table.className = 'table';

        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
        
        const headerRow = document.createElement('tr'); 
        const header = [
            'Aktion',
            'Bezeichnungsnummer',
            'Bezeichnung',
            'Kosten pro Einheit',
            'Steuer',
            'Stücke',
            'Einheit',
            'Gültig bis'
        ];
        header.forEach(element => {
            const th = document.createElement('th');
            th.textContent = element;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        
        if (Array.isArray(this.fetchedData.price_services)) {
            this.fetchedData.price_services.forEach(service => {
                const row = document.createElement('tr');
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="select-price" data-price="${service.id}">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${service.designation_number || ''}</td>
                    <td colspan="1">${service.designation || ''}</td>
                    <td colspan="1">${service.cost_per_unit || ''}</td>
                    <td colspan="1">${service.tax || ''}</td>
                    <td colspan="1">${service.pieces || ''}</td>
                    <td colspan="1">${service.unit || ''}</td>
                    <td colspan="1">${this.formatDate(service.valid_until)}</td>
                `;
                tbody.appendChild(row);
                const selectPriceElement = row.querySelector('.select-price');
                selectPriceElement.addEventListener('click', () => this.handlePriceSelection(service));
            });
        }

        table.appendChild(thead);
        table.appendChild(tbody);
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);
    }



    showObjectData() {
        const container = document.getElementById('pricelistObjectModalForm'); 
        if (!container) return;

        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';
        const table = document.createElement('table');
        table.className = 'table mt-3';
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');

        const headerRow = document.createElement('tr'); 
        const header = [
            'Aktion',
            'Objektnummer',
            'Bezeichnung',
            'Gültig bis',
        ];

        header.forEach(element => {
            const th = document.createElement('th');
            th.textContent = element;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);

        if (Array.isArray(this.fetchedData.price_objects)) {
            this.fetchedData.price_objects.forEach(object => {
                const row = document.createElement('tr');
                var updateUrl = PriceObjectUpdate.replace(/0/, object.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="select-object">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${object.designation_number || ''}</td>
                    <td colspan="1">${object.designation || ''}</td>
                    <td colspan="1">${this.formatDate(object.valid_until)}</td>
                `;
                tbody.appendChild(row);
                const selectObjectElement = row.querySelector('.select-object');
                selectObjectElement.addEventListener('click', () => this.handleObjectSelection(object));
            });
        }

        table.appendChild(thead);
        table.appendChild(tbody);
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);
    }

    showMachineryData() {
        const container = document.getElementById('pricelistMachinerysModalForm'); 
        if (!container) return;

        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';
        const table = document.createElement('table');
        table.className = 'table mt-3';
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');

        const headerRow = document.createElement('tr'); 
        const header = [
            'Aktion',
            'Maschinen Nummer',
            'Bezeichnung',
            'Kosten pro Einheit',
            'Stücke',
            'Steuer',
            'Gültig bis'
        ];

        header.forEach(element => {
            const th = document.createElement('th');
            th.textContent = element;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        if (Array.isArray(this.fetchedData.price_machinerys)) {
            this.fetchedData.price_machinerys.forEach(machinery => {
                const row = document.createElement('tr');
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="select-machinery">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${machinery.designation_number || ''}</td>
                    <td colspan="1">${machinery.designation || ''}</td>
                    <td colspan="1">${machinery.cost_per_unit || ''}</td>
                    <td colspan="1">${machinery.pieces || ''}</td>
                    <td colspan="1">${machinery.tax || ''}</td>
                    <td colspan="1">${this.formatDate(machinery.valid_until)}</td>
                `;
                tbody.appendChild(row);
                const selectMachineryElement = row.querySelector('.select-machinery');
                selectMachineryElement.addEventListener('click', () => this.handleMachinerySelection(machinery));
            });
        }
        table.appendChild(thead);
        table.appendChild(tbody);
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);
    }

    showTextyData(){
        const container = document.getElementById('pricelistTextModalForm'); 
        if (!container) return;

        const responsiveContainer = document.createElement('div');
        responsiveContainer.className = 'table-responsive';
        const table = document.createElement('table');
        table.className = 'table';
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');

        const headerRow = document.createElement('tr'); 
        const header = [
            'Aktion',
            'Text',
            'Gültig bis'
        ];

        header.forEach(element => {
            const th = document.createElement('th');
            th.textContent = element;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        if (Array.isArray(this.fetchedData.price_texts)) {
            this.fetchedData.price_texts.forEach(text => {
                const row = document.createElement('tr');
                var updateUrl = PriceTextUpdate.replace(/0/, text.id);
                row.innerHTML = `
                    <td colspan="1">
                        <div class="d-flex gap-3 align-items-center">
                            <div class="select-text">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                    </td>
                    <td colspan="1">${text.text || ''}</td>
                    <td colspan="1">${this.formatDate(text.valid_until)}</td>
                `;
                tbody.appendChild(row);

                const selectTextElement = row.querySelector('.select-text');
                selectTextElement.addEventListener('click', () => this.handleTextSelection(text));
            });
        }
        table.appendChild(thead);
        table.appendChild(tbody);
        responsiveContainer.appendChild(table);
        container.innerHTML = '';
        container.appendChild(responsiveContainer);
    }


    handlePriceSelection(service) {
        const activeModal = document.querySelector('.modal.show');
        const activeForm = activeModal ? activeModal.querySelector('form') : null;
        if (activeForm) {
            activeForm.querySelector('[name="designation_number"]').value = service.designation_number || '';
            activeForm.querySelector('[name="designation"]').value = service.designation || '';
            activeForm.querySelector('[name="tax"]').value = service.tax || '';
            activeForm.querySelector('[name="pieces"]').value = service.pieces || '';
            activeForm.querySelector('[name="unit"]').value = service.unit || '';
            const priceField = activeForm.querySelector('[name="cost_per_unit"]');
            let priceValue = parseFloat(String(service.cost_per_unit).replace(/[^\d.,]/g, '').replace(',', '.'));
            if (!isNaN(priceValue)) {
                priceField.value = priceValue.toFixed(2);
                priceField.dispatchEvent(new Event('change', { bubbles: true }));
            }
        } 
    }

    handleObjectSelection(object) {
        const activeModal = document.querySelector('.modal.show');
        const activeForm = activeModal ? activeModal.querySelector('form') : null;
        if (activeForm) {
            activeForm.querySelector('[name="designation_number"]').value = object.designation_number || '';
            activeForm.querySelector('[name="designation"]').value = object.designation || '';
        } else {
            console.error('Kein aktives Formular gefunden');
        }
    }

    handleMachinerySelection(machinery){
        const activeModal = document.querySelector('.modal.show');
        const activeForm = activeModal ? activeModal.querySelector('form') : null;

        if (activeForm) {
            activeForm.querySelector('[name="designation_number"]').value = machinery.designation_number || '';
            activeForm.querySelector('[name="designation"]').value = machinery.designation || '';
            
            const costField = activeForm.querySelector('[name="cost_per_unit"]');
            if (costField) {
                let costValue = parseFloat(String(machinery.cost_per_unit).replace(/[^\d.,]/g, '').replace(',', '.'));
                costField.value = !isNaN(costValue) ? costValue.toFixed(2) : '';
            }

            activeForm.querySelector('[name="pieces"]').value = machinery.pieces || '';
            activeForm.querySelector('[name="tax"]').value = machinery.tax || '';

        } else {
            console.error('Kein aktives Formular gefunden');
        }     
    }

    handleTextSelection(text){
        const activeModal = document.querySelector('.modal.show');
        const activeForm = activeModal ? activeModal.querySelector('form') : null;
        if(activeForm){
            activeForm.querySelector('[name="text"]').value = text.text || '';
        } else {
            console.error('Kein aktives Formular gefunden');
        }   
    }
}
