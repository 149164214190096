

export default class EmailHistory {
    constructor(url) {
        this.url = url; 
        this.loadHistory();
    }

    loadHistory() {
        fetch(this.url)
            .then(response => response.json())
            .then(data => {
                if(data.success) {
                    
                    const historyContainer = document.querySelector('#emailHistoryContainer');
                    data.history.forEach(email => {

                        historyContainer.innerHTML += `
                            <div class="list-group-item border-0 bg-light mb-2 p-3">
                                <div class="d-flex w-100 justify-content-between">
                                    <p><small>Datum: ${email.date}</small></p>
                                    <p><small>Mitarbeiter: ${email.employee}</small></p>
                                </div>
                                <p>Empfänger: ${email.recipient}</p>
                                <p>Betreff: ${email.subject}</p>
                                <p>Inhalt: ${email.body}</p>
                            </div>
                        `;
                    });
                }
            });
    }
}