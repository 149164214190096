

export default function loadSkonto(){
    const tbody = document.getElementById('skonto-body');
    if(!tbody) return;

    fetch('/invoices/skonto/', {
        headers: {'X-Requested-With': 'XMLHttpRequest'}
    })
    .then(res => res.json())
    .then(response => {
        if (response.success) {

            tbody.innerHTML = response.data.map(term => {
                let updateURL = skontoUpdate.replace(/\b0\b/, term.id);
                /* let deleteURL = paymentTermsDelete.replace(/\b0\b/, term.id); */
                return `
                    <tr>
                        <td>${term.name}</td>
                        <td>${term.skonto}</td>
                        <td>${term.days}</td>
                        <td>
                            <i class="fas fa-edit" data-url="${updateURL}"></i>
                        </td>
                    </tr>
                `;
            }).join('');

            tbody.querySelectorAll('.fa-edit').forEach(btn => {
                btn.addEventListener('click', function() {
                    const url = this.dataset.url;
                    window.location.href = url; 
                });
            });

        }
    });
}