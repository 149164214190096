

import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class InvoiceUpdate {

    constructor(){
        this.invoiceUpdateForm = document.querySelector('#inoicesUpdateForm');
        if(!this.invoiceUpdateForm) return;
        this.events();
    }

    events(){
        let timer;
        const saveForm = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {

                const disabledFields = this.invoiceUpdateForm.querySelectorAll('input[disabled], select[disabled], textarea[disabled]');
                const savedValues = new Map();
                
                disabledFields.forEach(field => {
                    savedValues.set(field.name, field.value);
                    field.disabled = false;
                });

                BaseAjaxSave({
                    target: this.invoiceUpdateForm,
                    preventDefault: () => {},
                    complete: () => {
                        disabledFields.forEach(field => {
                            field.disabled = true;
                            field.value = savedValues.get(field.name);
                        });
                    }
                });
            }, 300);
        };
        this.invoiceUpdateForm.addEventListener('change', saveForm);
        $(this.invoiceUpdateForm).find('#id_customer').on('select2:select', saveForm);
        $(this.invoiceUpdateForm).find('#id_payment_terms').on('select2:select', saveForm);
        $(this.invoiceUpdateForm).find('#id_service_date_start').on('changeDate', saveForm);
        $(this.invoiceUpdateForm).find('#id_service_date_end').on('changeDate', saveForm);
        $(this.invoiceUpdateForm).find('#id_skonto').on('select2:select', saveForm);
        $(this.invoiceUpdateForm).find('#id_payment_status').on('select2:select', saveForm);

    }

}