
import AlertHandler from '../../../../assets/js/modules/alertHandler'
export default class AccountsCreate{

    constructor(){
        this.accountsCreationForm = document.getElementById('accountsCreationForm');
        if(!this.accountsCreationForm) return
        this.accountsValidierung();
        this.events();
    }
    
    events(){
        this.accountsCreationForm.addEventListener('submit', (e) => {
            this.createAccounts(e);
        });
    } 

    accountsValidierung(){
        const username = document.querySelector('[name="username"]');
        const password1 = document.querySelector('[name="password1"]');
        const password2 = document.querySelector('[name="password2"]');

        const usernameFeedback = document.createElement('div');
        username.parentNode.appendChild(usernameFeedback);
        const btnSave = this.accountsCreationForm.querySelector('input[type="submit"]');
        if (!btnSave) return;

        username.addEventListener('input', function() {
            if(this.value.length > 3) {
                fetch(`/api/accounts/check_username/?username=${this.value}`)
                .then(response => response.json())
                .then(data => {
                    if(data.exists){
                        usernameFeedback.textContent = 'Benutzername existiert bereits.';
                        usernameFeedback.style.color = 'red';
                        btnSave.disabled = true;
                    }else{
                        usernameFeedback.textContent = '';
                        btnSave.disabled = false; 
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            }
        });

        const passwordFeedback = document.createElement('div');
        password1.parentNode.appendChild(passwordFeedback);
        function checkPasswords() {
            const username = document.querySelector('[name="username"]').value;
            const pass1 = password1.value;
            const pass2 = password2.value;
            let isValid = true;
            let feedbackMessage = '';

            password1.classList.remove('is-valid', 'is-invalid');
            password2.classList.remove('is-valid', 'is-invalid');

            if (pass1.length < 8) {
                feedbackMessage += 'Das Passwort muss mindestens 8 Zeichen lang sein.<br>';
                isValid = false;
            }

            if (!/[!@#$%^&*(),.?":{}|<>]/.test(pass1)) {
                feedbackMessage += 'Das Passwort muss mindestens ein Sonderzeichen enthalten.<br>';
                isValid = false;
            }

            if (username && pass1.toLowerCase().includes(username.toLowerCase())) {
                feedbackMessage += 'Das Passwort darf den Benutzernamen nicht enthalten.<br>';
                isValid = false;
            }

            if (pass1 !== pass2) {
                feedbackMessage += 'Die Passwörter stimmen nicht überein.<br>';
                isValid = false;
            }

            if (pass1.length > 0) {
                password1.classList.add(isValid ? 'is-valid' : 'is-invalid');
            }
            if (pass2.length > 0) {
                password2.classList.add(isValid ? 'is-valid' : 'is-invalid');
            }

            passwordFeedback.innerHTML = feedbackMessage;
            passwordFeedback.style.color = isValid ? 'green' : 'red';
            btnSave.disabled = !isValid;
        }

        password1.addEventListener('input', checkPasswords);
        password2.addEventListener('input', checkPasswords);

    }

    createAccounts(event){
        event.preventDefault(); 
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    AlertHandler.showAlert(body.message, 'success');
                    setTimeout(() => {
                        window.location.href = `/accounts/list/`;
                    }, 300);
                } else {
                    console.log('Fehler beim Speichern!', body.errors || body.message);
                    AlertHandler.showAlert(body.message, 'danger');
                }
            }
        })
        .catch(error => {
            console.error('Error:', error);
        }); 
    }


}