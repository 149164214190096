

import AlertHandler from '../../../assets/js/modules/alertHandler';
import RenderNotes from './module/renderNotes'
import { Modal } from 'bootstrap';
import updateNotes from './module/updateNotes';
import deleteNotes from './module/deleteNotes';

document.addEventListener('DOMContentLoaded', function () {
    const Rendernotes = new RenderNotes();

    const openModalNotesBtn = document.querySelectorAll('.open-modal-notes');
    if(!openModalNotesBtn) return;

    openModalNotesBtn.forEach(button => {
        button.addEventListener('click', event => {
            event.preventDefault();
            const url = event.currentTarget.getAttribute('data-url');
            loadModal(url);
        });
    });

    async function loadModal(url) {
        const modalElement = document.getElementById('genericModal');
        if (!modalElement) {
            console.error('Modal element not found');
            return;
        }

        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data.html) {
                modalElement.querySelector('.modal-content').innerHTML = data.html;

                const modal = new Modal(modalElement);
                modal.show();

                const form = modalElement.querySelector('form');
                if (form) {
                    form.setAttribute('action', url);
                    form.addEventListener('submit', async (event) => {
                        event.preventDefault();
                        const formData = new FormData(form);
                        const postResponse = await fetch(form.action, {
                            method: 'POST',
                            body: formData,
                        });
                        const data = await postResponse.json();
                        if (data.success) {
                            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
                            modal.hide();

                            const notes = new RenderNotes();
                            notes.displayData();
                        } else {
                            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
                        }
                    });
                }

            } else {
                console.error('No HTML content in response');
            }
        } catch (error) {
            console.error('Failed to load modal content:', error);
        }
    }

}); 