
import AlertHandler from '../../../assets/js/modules/alertHandler';
import { Modal } from 'bootstrap';


export default class GpsRoute {
    constructor(){
        this.events();
        this.observeDOMChanges();
        this.modalIsOpen = false;
    }

    events(){
        this.openModal(document);
    }

    openModal(element){
        element.querySelectorAll('.gpsModal').forEach(button => {
            button.addEventListener('click', event => {
                event.preventDefault();
                const id = event.currentTarget.getAttribute('data-id');
                const url = event.currentTarget.getAttribute('data-create-route');
                this.routeloadModal(event, id, url);
            });
        });
    }

    routeloadModal(event, id, url){
        const modalTrigger = event.target.closest('.gpsModal');
        if (!modalTrigger) return;

        const modalElement = document.getElementById('TimetrackingModal');
        if (modalElement) {
            if (this.modalIsOpen) return;

            this.modalIsOpen = true;

            const mapElement = modalElement.querySelector('#map');
            if (mapElement) {
                mapElement.remove();
            }

            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }

            new Modal(modalElement).show();
            this.loadModalContent(id, modalElement, url);
            
            modalElement.addEventListener('hidden.bs.modal', () => {
                this.modalIsOpen = false;
            }, { once: true });
        }
    }

    loadModalContent(id, modalElement, url){
        fetch(url).then(response => {
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Daten');
            }
            return response.json();
            }).then(data => {
                const modalBody = modalElement.querySelector('.modal-body');
                modalBody.innerHTML = data.html;
            
                new Modal(modalElement).show();


                const form = modalBody.querySelector('#RouteFrom');
                if (form) {
                    const hiddenInput = document.createElement('input');
                    hiddenInput.type = 'hidden';
                    hiddenInput.name = 'object';
                    hiddenInput.value = id;
                    form.appendChild(hiddenInput);
                }
                
            }).catch(error => {
                console.error('Fehler:', error);
            });

            modalElement.addEventListener('hidden.bs.modal', () => {
                const backdrops = document.querySelectorAll('.modal-backdrop');
                backdrops.forEach(backdrop => backdrop.parentNode.removeChild(backdrop));
                const modalInstance = Modal.getInstance(modalElement);
                if (modalInstance) {
                    modalInstance.dispose();
                }
            });
    }


    observeDOMChanges() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === 1 && node.matches('#RouteFrom')) {
                        this.handleFormFound(node);
                    }
                });
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    handleFormFound(form) {
        form.addEventListener('submit', this.submitGpsForm);
    }


    submitGpsForm(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const action = form.action;
        const method = form.method;
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        formData.append('customer', CustomerId);
        formData.append('order', ordersIdVar);

        fetch(action, {
            method: method,
            body: formData,
            headers: {
                'X-CSRFToken': csrfToken,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }) 
        .then(response => response.json()) 
        .then(data => {
            if (data.success) {
                console.log("Erfolgreich gespeichert:", data.message);
                AlertHandler.showAlert(data.message, 'success');
                $('#TimetrackingModal').modal('hide');
            } else {
                if (data.errors) {
                    console.error("Formularfehler:", JSON.parse(data.errors));
                } else if (data.error) {
                    console.error("Serverfehler:", data.error);
                } else {
                    console.error("Unbekannter Fehler:", data);
                }
            }

        })
        .catch(error => {
            console.error('Error:', error);
        });
    }


}