


export default class GenericTableCalculator {
    constructor(tableId, config) {
        this.table = document.getElementById(tableId);
        if (!this.table) return;
        this.config = config;
        this.totals = {};
        this.fetchSkontoData();
    }

    init() {
        if (!this.table) return;
        this.calculate();
    }


    fetchSkontoData() {
        try {
            if (typeof invoiceSkontoUrl === 'undefined') {
                //console.error('invoiceSkontoUrl ist nicht definiert');
                return;
            }
            this.config.footerRows = this.config.footerRows.filter(row => 
                !row.totalName.includes('skonto') && row.totalName !== 'reduced_amount'
            );
    
            fetch(invoiceSkontoUrl, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
            })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data && data.data.skonto_percent > 0) {
                    const total = this.totals.total || 0;
                    this.totals.skonto_amount = total * (data.data.skonto_percent / 100);
                    this.totals.reduced_amount = total - this.totals.skonto_amount;
                    this.totals.skonto_percent = data.data.skonto_percent;
                    this.totals.skonto_date = data.data.skonto_date;
                    

                    const skontoField = document.getElementById('id_skonto_value');
                    if (skontoField) {
                        const skontoAmount = this.totals.total * (this.totals.skonto_percent / 100);
                        skontoField.value = skontoAmount.toFixed(2);
                    }

                    this.config.footerRows.push(
                        {label: `Skonto (${this.totals.skonto_percent}%) bis ${this.totals.skonto_date}`, totalName: 'skonto_amount'},
                        {label: 'Betrag mit Skonto', totalName: 'reduced_amount'}
                    );

                    this.updateFooter(this.totals);
                }
            })
            .catch(error => {
                console.error('Fehler beim Laden der Skonto-Daten:', error);
            });
        } catch (error) {
            console.error('Fehler in fetchSkontoData:', error);
        }
    }

    calculate() {
        const oldSkonto = {
            skonto_amount: this.totals.skonto_amount,
            reduced_amount: this.totals.reduced_amount,
            skonto_percent: this.totals.skonto_percent,
            skonto_date: this.totals.skonto_date
        };
        this.totals = {
            subtotal: 0,
            tax: 0,
            total: 0,
            discount: 0, 
            skonto_amount: oldSkonto.skonto_amount,
            reduced_amount: oldSkonto.reduced_amount,
            skonto_percent: oldSkonto.skonto_percent,
            skonto_date: oldSkonto.skonto_date
        };
        this.table.querySelectorAll('tbody tr').forEach(row => {
            if (this.config.rowTypes.includes(row.dataset.type)) {
                this.config.calculations.forEach(calc => {
                    const values = calc.columns.map(col => {
                        let cellIndex = 0;
                        let currentCol = 0;
                        while (currentCol < col && cellIndex < row.cells.length) {
                            currentCol += parseInt(row.cells[cellIndex].getAttribute('colspan') || 1);
                            cellIndex++;
                        }
                        if (row.cells[cellIndex]) {
                            return this.parseNumber(row.cells[cellIndex].textContent);
                        } 
                    });
                    const result = calc.formula(...values);
                    if (calc.resultColumn !== undefined) {
                        let cellIndex = 0;
                        let currentCol = 0;
                        while (currentCol < calc.resultColumn && cellIndex < row.cells.length) {
                            currentCol += parseInt(row.cells[cellIndex].getAttribute('colspan') || 1);
                            cellIndex++;
                        }
                        if (row.cells[cellIndex]) {
                            row.cells[cellIndex].textContent = this.formatNumber(result);
                        }
                    }
                    this.totals[calc.name] = (this.totals[calc.name] || 0) + result;
                });

                const price = this.parseNumber(row.cells[3].textContent);
                const qty = this.parseNumber(row.cells[6].textContent);
                const discountPercent = this.parseNumber(row.cells[5].textContent);
                this.totals.discount += (price * qty) * (discountPercent / 100);
            }
        });

        this.totals.total = this.totals.subtotal + this.totals.tax;
        this.updateFooter(this.totals);
        const fieldsToUpdate = {
            'id_amount': this.totals.total,
            'id_discount': this.totals.discount,
            'id_tax': this.totals.tax
        };
    
        for (const [fieldId, value] of Object.entries(fieldsToUpdate)) {
            const field = document.getElementById(fieldId);
            if (field) {
                const numberValue = value || 0;
                if (field.type === 'hidden') {
                    field.value = numberValue.toFixed(2);
                } else {
                    field.value = this.formatNumber(numberValue);
                }
            }
        }
    }

    updateFooter(totals) {
        let tfoot = document.querySelector('tfoot');
        if (!tfoot) {
            tfoot = document.createElement('tfoot');
            this.table.parentElement.appendChild(tfoot);
        }
        
        tfoot.innerHTML = this.config.footerRows.map(row =>
            `<tr style="border:none;">
                <td colspan="${this.config.footerColspan}" style="border:none;" class="m-1 p-1"></td>
                <td style="border:none;" class="m-1 p-1">${row.label}:</td>
                <td style="border:none;" class="m-1 p-1">${this.formatNumber(totals[row.totalName] || 0)}</td>
            </tr>`
        ).join('');
    }

    parseNumber(value) {
        return parseFloat(value.replace(/[^\d.,-]/g, '').replace(',', '.')) || 0;
    }

    formatNumber(value) {
        return (value || 0).toFixed(2).replace('.', ',') + ' €';
    }
}