

import { Modal } from 'bootstrap';


import AlertHandler from "../modules/alertHandler";
import GenericTableCalculator from "./GenericTableCalculator";



export function BaseAjaxSave(event, instance=null){
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const action = form.action;
    const method = form.method;
    const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 
    fetch(action, {
        method: method,
        body: formData,
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest'
        }
    }) 
    .then(response => response.json()) 
    .then(data => {
        
        if(data.success_url) window.location.href = data.success_url
        if (data.success) {
            AlertHandler.showAlert(data.message, 'success');

            if (typeof window.reloadPositionsList === 'function') {
                window.reloadPositionsList();
            } 

            if (typeof window.reloadActivitiesList === 'function') {
                window.reloadActivitiesList();
            } 

            if (typeof window.reloadPriceList === 'function') {
                window.reloadPriceList();
            }

            // Modal schließen
            document.querySelectorAll('.modal').forEach(modal => {
                const bsModal = Modal.getInstance(modal);
                if (bsModal) {
                    bsModal.hide();
                }
            });
 
        } else {
            console.log('FEHLER:', {
                data: data,
                errors: data.errors,
                message: data.message
            });
            let errorMessage = '';
            
            if (data.errors) {
                for (const [field, errors] of Object.entries(data.errors)) {
                    errorMessage += `${field}: ${errors.join(', ')}\n`;
                }
            }
            
            console.log(errorMessage)
            AlertHandler.showAlert(errorMessage || data.message || 'Unbekannter Fehler', 'danger');
        }   
    })
    .catch(error => {
        console.error('Error:', error);
        AlertHandler.showAlert(error || 'An unexpected error occurred.', 'danger');
    });
}; 


export async function loadOptions(type, modalElement) {
    try {
        const queryParams = new URLSearchParams({ offer_type: type }).toString();
        const url = `${loadOptionsApi}?${queryParams}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const result = await response.json();
            const selectElement =  modalElement.querySelector('.select2-element');
            if (selectElement) {

                if ($(selectElement).hasClass("select2-hidden-accessible")) {
                    $(selectElement).select2('destroy');
                }
                
                const formattedData = result.map(item => ({
                    id: item.id,
                    text: `${item.designation_number} - ${item.designation}`,
                    offer_type: type,
                    ...item
                }));
                formattedData.unshift({ id: '', text: '--- Wählen Sie einen Wert ---' });
                setTimeout(() => {
                $(selectElement).empty().select2({
                    data: formattedData,
                    width: '100%',
                    dropdownParent: $('.select-content'),
                }).on('select2:select', handleSelectChange);
            }, 300);
            } else {
                console.log('Select2 element not found.');
            }
        } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export function handleSelectChange(event) {
    var selectedOption = event.params.data;
    const currentRow = document.querySelector(`.select-content`);
    if(selectedOption && currentRow){
        if (selectedOption.offer_type === 'service') {
            var customDesignationNumber = currentRow.querySelector('[name="designation_number"]');
            var description = currentRow.querySelector('[name="designation"]');
            var cost = currentRow.querySelector('[name="cost_per_unit"]');
            var unit = currentRow.querySelector('[name="unit"]');
            var discount = currentRow.querySelector('[name="discount"]');
            var tax = currentRow.querySelector('[name="tax"]');
            var service_category = currentRow.querySelector('[name="service_category"]');
            var service = currentRow.querySelector('[name="service"]');

            if (customDesignationNumber) customDesignationNumber.value = selectedOption.designation_number ? selectedOption.designation_number: '';
            if (description) description.value = selectedOption.designation ? selectedOption.designation : '';
            if (cost) cost.value = selectedOption.cost_per_unit ? selectedOption.cost_per_unit: '';
            if (unit) unit.value = selectedOption.unit ? selectedOption.unit: '';
            if (tax) tax.value = selectedOption.tax ? selectedOption.tax: '';
            if (discount) discount.value = selectedOption.discount ? selectedOption.discount: '0,00';
            if (service_category) service_category.value = selectedOption.service_category ? selectedOption.service_category: '';
            if (service) service.value = selectedOption.id ? selectedOption.id: '';

        } else if (selectedOption.offer_type === 'object') {
            var designationNumber = currentRow.querySelector('[name="designation_number"]');
            var designation = currentRow.querySelector('[name="designation"]');
            var object = currentRow.querySelector('[name="object"]');
            if (designationNumber) designationNumber.value = selectedOption.designation_number;
            if (designation) designation.value = selectedOption.designation;
            if (object) object.value = selectedOption.id;
        } else if (selectedOption.offer_type === 'machinerys') {
            
            var maschinerysName = currentRow.querySelector('[name="name"]');
            var maschinerysDesignationNumber = currentRow.querySelector('[name="designation_number"]');
            var maschinerysDescription = currentRow.querySelector('[name="designation"]');
            var maschinerysUnit = currentRow.querySelector('[name="unit"]');
            var maschinerysStatus = currentRow.querySelector('[name="status"]')
            var maschinerysCost = currentRow.querySelector('[name="cost_per_unit"]');
            var machinerys = currentRow.querySelector('[name="machinerys"]');

            if(maschinerysName) maschinerysName.value = selectedOption.name ? selectedOption.name: '';
            if(maschinerysDesignationNumber) maschinerysDesignationNumber.value = selectedOption.designation_number ? selectedOption.designation_number: '';
            if(maschinerysDescription) maschinerysDescription.value = selectedOption.designation ? selectedOption.designation: '';
            if(maschinerysUnit) maschinerysUnit.value = selectedOption.unit ? selectedOption.unit: '';
            if(maschinerysStatus) maschinerysStatus.value = selectedOption.status ? selectedOption.status: '';
            if(maschinerysCost) maschinerysCost.value = selectedOption.cost_per_unit ? selectedOption.cost_per_unit: '';
            if(machinerys) machinerys.value = selectedOption.id ? selectedOption.id: '';

        }
    } else {
        console.error('Could not find row or selected option is missing', { currentRow, selectedOption });
    }
}






