
// Einstellungen Seiten speichern für PFD und E-Mail.

import AlertHandler from "../../../assets/js/modules/alertHandler";

export default class PdfSettings{
    constructor(){
        this.events();
    }

    events(){
        const formIds = ['email_settings_form', 'pdf_settings_form'];
        formIds.forEach(formId => {
            const form = document.getElementById(formId);
            if (form) {
                form.addEventListener('submit', this.createPdfSettings.bind(this));
            }
        });
    }

    createPdfSettings(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const action = form.action;
        const method = form.method;
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 

        fetch(action, {
            method: method,
            body: formData,
            headers: {
                'X-CSRFToken': csrfToken,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }) 
        .then(response => response.json()) 
        .then(data => {
    
            if(data.success_url) window.location.href = data.success_url
            if (data.success) {
    
                AlertHandler.showAlert(data.message, 'success');
                
            } else {
                console.error('Error saving:', data.errors);
                const allErrorMessages = Object.values(data.errors).flat().join(', ');
                AlertHandler.showAlert(allErrorMessages, 'danger');
            }   
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert(error || 'An unexpected error occurred.', 'danger');
        });

    }

}