import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';

export default class objectChangeHistory {
    constructor() {
        this.changeHistoryContainer = document.querySelector('#change-history-container-object');
        if(!this.changeHistoryContainer) return;
        this.event();
    }

    event() {
        if (this.changeHistoryContainer) {
            const modelName = 'objects';
            const objectId = objectIdVar;
            const fieldsToShow = ['designation_number', 'designation'];
            const fieldLabels = {
                designation_number: 'Bezeichnungsnummer',
                designation: 'Bezeichnung',
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-object', fieldsToShow, fieldLabels);
        }
    }
} 