
import CreateProduk from "./module/CreateProducts";
import produktsUpdateModalOpen from "./module/UpdateProducts";

import GenericAPI from "../../../assets/js/modules/GenericAPI";

document.addEventListener('DOMContentLoaded', function() {
    new CreateProduk();
    
    const produkts = document.querySelector('#productTable');
    if(!produkts) return;
    produktsUpdateModalOpen();


    const renderProductDetail = (data) => {
        const container = document.getElementById('productDetailContainer');
        container.innerHTML = '';
        const template = `
            <div class="card-body">
                <div class="d-flex pb-3 justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <button data-id="${data.id}" type="button" class="btn btn-sm btn-light produkt-update">Bearbeiten</button>
                    </div>
                    <div class="d-flex gap-2 align-items-center">
                        <button type="button" class="btn btn-sm btn-success">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                        <button type="button" class="btn btn-sm btn-danger">
                            <i class="fa-solid fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card w-100">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">Bezeichnung: ${data.designation || '-'}</li>
                        <li class="list-group-item">Produktnummer: ${data.designation_number || '-'}</li>
                        <li class="list-group-item">Produkttyp: ${data.product_type || '-'}</li>
                        <li class="list-group-item">Erstellt von: ${data.created_by || '-'}</li>
                        <li class="list-group-item">Anzahl: ${data.pieces || '0'}</li>
                        <li class="list-group-item">Kosten pro Einheit: ${data.cost_per_unit ? data.cost_per_unit + ' €' : '0,00 €'}</li>
                    </ul>
                </div>
            </div>
        `;
        container.innerHTML = template;
    }

    const handleRowClick = async (event) => {
        const clickedRow = event.target.closest('.produkt-row');
        if (clickedRow) {
            const productId = clickedRow.dataset.id;
            const url = productDetail.replace('0', productId);
            const api = new GenericAPI(url);
            const data = await api.loadData();
            renderProductDetail(data);
        }
    };

    const observer = new MutationObserver(() => {
        produkts.querySelectorAll('.produkt-row').forEach(row => {
            row.addEventListener('click', handleRowClick);
        });
    });
    observer.observe(produkts, { childList: true, subtree: true });


    let productTable = new DataTable('#productTable', {
        ajax: {
            url: '/api/products/products/',
            dataSrc: '',
        },
        columns: [
            { data: 'designation', title: 'Bezeichnung' },
            { data: 'designation_number', title: 'Artikelnummer' },
            { data: 'product_type', title: 'Produkttyp' },
            { data: 'unit', title: 'Einheit' },
            { data: 'pieces', title: 'Stückzahl' },
            { data: 'forecast_pieces', title: 'Prognose' }
        ],
        createdRow: function (row, data, dataIndex) {
            $(row).attr('data-id', data.id);
            $(row).addClass('produkt-row');
        },
        language: {
            "emptyTable":     "Keine Daten in der Tabelle vorhanden",
            "info":           "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":      "0 bis 0 von 0 Einträgen",
            "infoFiltered":   "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":     "_MENU_ Einträge anzeigen",
            "loadingRecords": "Wird geladen...",
            "processing":     "Bitte warten...",
            "search":         "Suchen:",
            "zeroRecords":    "Keine Einträge vorhanden",
            "paginate": {
                "first":      "Erste",
                "previous":   "Zurück",
                "next":       "Nächste",
                "last":       "Letzte"
            }
        },
        pageLength: 10,
        responsive: true
    });
});