// agriflow/core/static/module/numberRange.js

const getNextNumerView = (modal) => {
    fetch(`${getNextNumber}?modal=${modal}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
        },
    })
    .then(response => response.json())
    .then(data => {
        const designationNumberElement = document.getElementById('id_designation_number');
        
        if (data.error) {
            console.error('Fehler:', data.error);
            designationNumberElement.value = data.message || 'Ein Fehler ist aufgetreten.';
        } else if (data.formatted_number) {
            designationNumberElement.value = data.formatted_number;
        } else {
            designationNumberElement.value = data.message;
        }
    })
    .catch(error => {
        console.error('Fehler:', error);
        document.getElementById('id_designation_number').value = 'Ein Fehler ist aufgetreten';
    });
}

export default getNextNumerView;


// ToDo das ist nicht so gut denke ich.
/* const formIds = ['offer_service_form', 'orders_service_form'];

const observer = new MutationObserver((mutations, obs) => {
    for (let id of formIds) {
        const form = document.getElementById(id);
        if (form) {
            const nextNumber = form.getAttribute('data-next-number');
            const designationNumberElement = form.querySelector('#id_designation_number');
            fetch(`${getNextNumber}?modal=${nextNumber}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.error('Fehler:', data.error);
                    designationNumberElement.value = data.message || 'Ein Fehler ist aufgetreten.';
                } else if (data.formatted_number) {
                    designationNumberElement.value = data.formatted_number;
                } else {
                    designationNumberElement.value = data.message;
                }
            })
            .catch(error => {
                console.error('Fehler:', error);
                designationNumberElement.value = 'Ein Fehler ist aufgetreten';
            });
        
            obs.disconnect();
            break;
        }
    }
});

observer.observe(document.body, {
    childList: true,
    subtree: true
}); */