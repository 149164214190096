

import { addTimer, startTimer, stopTimer, pauseTimer, checkExistingTimer } from "./module/Tracking";
import { DataAdminPannel } from "./module/TrackingAdminPannel";


document.addEventListener('DOMContentLoaded', function () {

    if(!document.querySelector('#addTimerBtn')) return;

    document.querySelector('#addTimerBtn').addEventListener('click', addTimer);
    document.getElementById('timerContainer').addEventListener('click', (event) => {
        if (event.target.classList.contains('startTimer')) {
            startTimer(event.target);
        } else if (event.target.classList.contains('stopTimer')) {
            stopTimer(event.target);
        } else if (event.target.classList.contains('pauseTimer')) {
            pauseTimer(event.target);
        }
    });

    const timerSections = document.querySelectorAll('.timerSection');
    timerSections.forEach(checkExistingTimer);

    // Admin Pannel
    document.querySelector('#pills-profile-tab').addEventListener('click', DataAdminPannel);


});