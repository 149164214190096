

import machinerysCreate from './module/machinerysCreate';
import machinerysUpdate from './module/machinerysUpdate';
import machinerysChangeHistory from './module/machinerysChangeHistory';

import getNextNumerView from '../../../core/static/module/numberRange';
import ActivitiesList from '../../../activities/static/js/module/ActivitiesList';
import checkReferences from '../../../assets/js/common/CheckReferences';

document.addEventListener('DOMContentLoaded', function () {
    const machineryscreate = new machinerysCreate();
    const machinerysupdate = new machinerysUpdate();
    const machineryschangehistory = new machinerysChangeHistory();
    
    if(document.getElementById('activities-machinerys-container')){
        const activitieslist = new ActivitiesList(activitiesList, 'activities-machinerys-container');
        window.reloadActivitiesList = function () {
            if (typeof activitieslist !== 'undefined' && activitieslist) {
                activitieslist.init();
            }
        };
    }

    if(document.querySelector('[data-permission="delete_machinerys"]')){
        checkReferences('machinerys', 'Machinerys', machineryId);
    }

    // Nummer
    const machinerysCreateFrom = document.getElementById('machineryCreateForm');
    if(!machinerysCreateFrom) return;
    getNextNumerView('MAC');

}); 