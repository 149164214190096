

import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class CreateStorage{
    constructor(){
        this.storageCreateForm = document.querySelector('#storageCreateForm');
        if(!this.storageCreateForm) return;
        this.event();
    }
    event(){
        this.storageCreateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }
}