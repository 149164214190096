import AlertHandler from '../../../../assets/js/modules/alertHandler'

export default class objektCreate {

    constructor(){
        this.events();
    }

    events(){
        const objektCreateBtn = document.querySelector('#objectFormsCreate');
        if(!objektCreateBtn) return;
        objektCreateBtn.addEventListener('submit', this.objektCreateSave.bind(this));
    }

    objektCreateSave(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 

        const accessToken = this.getCookie('access_token');
        /* if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        } */

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    console.log('Erfolgreich gespeichert');
                    AlertHandler.showAlert(body.message, 'success');

                    if (body.id) {
                        setTimeout(() => {
                            window.location.href = `/objects/update/${body.id}/`;
                        }, 300); 
                    }
                } else {
                    console.log('Fehler beim Speichern!', body.errors || body.message);
                    AlertHandler.showAlert(body.message, 'danger');
                }
            } else {
                console.log('Server returned an error:', body.errors || body.message);
                AlertHandler.showAlert(body.message, 'danger');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });

    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }


}