// <!--agriflow/customers/static/js/modles/importCustomers.js-->

export default async function CustomerImport() {
    const fileInput = document.getElementById('fileInput')
    const file = fileInput.files[0]
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const formData = new FormData()
    formData.append('file', file)

    try {
        const response = await fetch(import_url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRFToken': token,
                'X-Requested-With': 'XMLHttpRequest'
            }
        })

        const data = await response.json()
        console.log(data)
    } catch (error) {
        console.log('importCustomers.js ' + error)
    }
}

/* export default document.getElementById("uploadForm").addEventListener("submit", async function(event) {
    event.preventDefault();
    const fileInput = document.getElementById("fileInput");
    const file = fileInput.files[0];

    if (!file) {
        alert("Bitte eine Datei auswählen.");
        return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await fetch(import_url, {
            method: "POST",
            body: formData
        });

        const data = await response.json();
        document.getElementById("responseMessage").innerText = data.message || data.error;
    } catch (error) {
        document.getElementById("responseMessage").innerText = "Fehler beim Upload.";
    }
}); */