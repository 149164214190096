import 'bootstrap';


//import addressSelection from './modules/addressSelection'
import genericModal from './modules/genericModal'
import genericEditModal from './modules/genericEditModal'
import genericDeleteModal from './modules/genericDeleteModal'
import dataTableListView from './modules/dataTableListView'
import checkReferences from './common/CheckReferences';

// 
import servieModalOpen from './common/SaveModalPostions';

document.addEventListener('DOMContentLoaded', function () {
    //const addressselection = new addressSelection();
    const genericmodal = new genericModal();
    const genericeditmodal = new genericEditModal();
    const genericdeletemodal = new genericDeleteModal();
    const datatablelistview = new dataTableListView();

    servieModalOpen();

});

//sidebar-open-close
document.addEventListener("DOMContentLoaded", function() {
    /* var toggleButton = document.querySelector(".sidebar-open-close");
    var wrapperNavbar = document.querySelector(".wrapper-navbar");
    var wrapperMain = document.querySelector(".wrapper-main");
    var wrapperSidebar = document.querySelector(".wrapper-sidebar"); 
    if(!wrapperSidebar) return;
    var logoDesktop = document.querySelector("#logo-desktop");
    var logoMobile = document.querySelector("#logo-mobile");
    var btnSettings = document.querySelector("#btn-settings");

    var menuTitle = document.querySelectorAll('.menu-title');
    var menuLink = document.querySelectorAll('.menu-link');

    function applySidebarStatus(status) {
        if (status === "active") {
            // wrapperNavbar.classList.add("active");
            // wrapperMain.classList.add("active");
            wrapperSidebar.classList.add("active");
            logoDesktop.classList.add("active");
            logoMobile.classList.add("active");
            btnSettings.classList.add("active");

            menuLink.forEach(function(link){
                link.classList.add("justify-content-start");
                link.classList.remove('justify-content-center');
            });

            menuTitle.forEach(function(item){
                item.classList.remove('d-none');
            });

        } else {
            // wrapperNavbar.classList.remove("active");
            // wrapperMain.classList.remove("active");
            wrapperSidebar.classList.remove("active");
            logoDesktop.classList.remove("active");
            logoMobile.classList.remove("active");
            btnSettings.classList.remove("active");

            menuTitle.forEach(function(item){
                item.classList.add('d-none');
            });

            menuLink.forEach(function(link){
                link.classList.remove("justify-content-start");
                link.classList.add('justify-content-center');
            });
        }

    }

    var sidebarStatus = localStorage.getItem("sidebarStatus") || "inactive";
    applySidebarStatus(sidebarStatus);

    /* toggleButton.addEventListener("click", function() {
        if (wrapperNavbar.classList.contains("active")) {
            localStorage.setItem("sidebarStatus", "inactive");
            applySidebarStatus("inactive");
        } else {
            localStorage.setItem("sidebarStatus", "active");
            applySidebarStatus("active");
        }
    }); */



    const sidebar = document.querySelector('.wrapper-sidebar');
    const pinButton = document.querySelector('.pin-sidebar');
    const wrapperMain = document.querySelector(".wrapper-main");
    const threshold = 20;
    const overlay = document.createElement('div');
    overlay.className = 'sidebar-overlay';
    document.body.appendChild(overlay);

    if (!sidebar || !pinButton || !wrapperMain) {
        //console.error('Elemente nicht gefunden');
        return;
    }

    // Status beim Laden prüfen
    const sidebarStatus = localStorage.getItem("sidebarStatus") || "inactive";
    if(sidebarStatus === "active") {
        wrapperMain.classList.add('active');
        sidebar.classList.add('active', 'pinned');   
        overlay.classList.remove('active');  
    }

    pinButton.addEventListener('click', function() {
        if(wrapperMain.classList.contains("active")){
            wrapperMain.classList.remove('active');
            sidebar.classList.remove('active', 'pinned');
            overlay.classList.add('active');
            localStorage.setItem("sidebarStatus", "inactive"); // Status speichern
        } else {
            wrapperMain.classList.add('active');
            sidebar.classList.add('active', 'pinned');   
            overlay.classList.remove('active');
            localStorage.setItem("sidebarStatus", "active"); // Status speichern
        }
    });
    
    document.addEventListener('mousemove', function(e) {
        if (!wrapperMain.classList.contains("active")) {
            if (e.clientX <= threshold) {
                sidebar.classList.add('active');
                overlay.classList.add('active');
            } else if (e.clientX > 250) {
                sidebar.classList.remove('active');
                overlay.classList.remove('active');
            }
        }
    });

});