



export default function loadPaymentTerms() {
    const tbody = document.querySelector('#payment-terms-body');
    if(!tbody) return;

    fetch('/invoices/payment-terms/', {
        headers: {'X-Requested-With': 'XMLHttpRequest'}
    })
    .then(res => res.json())
    .then(response => {
        if (response.success) {

            // paymentTermsUpdate
            tbody.innerHTML = response.data.map(term => {
                let updateURL = paymentTermsUpdate.replace(/\b0\b/, term.id);
                let deleteURL = paymentTermsDelete.replace(/\b0\b/, term.id);
                return `
                    <tr>
                        <td>${term.name}</td>
                        <td>${term.days}</td>
                        <td>
                            <i class="fas fa-edit" data-url="${updateURL}"></i>
                            <i class="fas fa-trash" data-url="${deleteURL}"></i>
                        </td>
                    </tr>
                `;
            }).join('');


            tbody.querySelectorAll('.fa-edit').forEach(btn => {
                btn.addEventListener('click', function() {
                    const url = this.dataset.url;
                    window.location.href = url; 
                });
            });

            tbody.querySelectorAll('.fa-trash').forEach(btn => {
                btn.addEventListener('click', function() {
                    const url = this.dataset.url;
                    window.location.href = url; 
                });
            });

        }
    });
}


document.addEventListener('DOMContentLoaded', loadPaymentTerms);