// agriflow/access_controls/static/js/access_controls.js


import accessControlsListe from './modles/accessControlsListe'
import loadUserPermissions from './modles/checkAccess';

document.addEventListener('DOMContentLoaded', function () {
    const accesscontrolsliste = new accessControlsListe();

    loadUserPermissions();
    
});

export async function checkUserPermission(requiredPermission) {
    const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

    if (!accessToken) {
        return false;
    }

    const response = await fetch('/api/access/user-permissions/', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        console.error(`Fehler: ${response.status}`);
        return false;
    }

    const data = await response.json();
    return data.permissions.includes(requiredPermission);
}