export default class importBanking {
    constructor(importForm) {
        this.importForm = importForm;
        this.table = document.getElementById("dataTable");
        this.thead = document.getElementById("tableHead");
        this.tbody = document.getElementById("tableBody");
        this.currentData = null;
    }

    init() {
        this.importForm.addEventListener("submit", (event) => {
            event.preventDefault();
            const form = event.target;
            const formData = new FormData(form);
            const action = form.action;
            const method = form.method;
            const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

            fetch(action, {
                method: method,
                body: formData,
                headers: {
                    "X-CSRFToken": csrfToken,
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        this.currentData = data.data;
                        const headers = Object.keys(data.data[0]);

                        this.thead.innerHTML = `
                            <tr>
                                ${headers.map((header) => `<th>${header}</th>`).join("")}
                            </tr>
                            <tr>
                                ${headers
                                    .map(
                                        () => `
                                    <th>
                                        <select class="form-select form-select-sm">
                                            <option value="">Bitte wählen...</option>
                                            <option value="payment_reference">Zahlungsreferenz</option>
                                            <option value="purpose">Verwendungszweck</option>
                                            <option value="payment_date">Buchungsdatum</option>
                                            <option value="amount">Betrag</option>
                                            <option value="misc">Sonstiges</option>
                                        </select>
                                    </th>
                                `
                                    )
                                    .join("")}
                            </tr>
                        `;

                        this.tbody.innerHTML = data.data
                            .map(
                                (row) => `
                            <tr>
                                ${headers
                                    .map((header) => `<td>${row[header] || ""}</td>`)
                                    .join("")}
                            </tr>
                        `
                            )
                            .join("");

                        this.table.classList.remove("d-none");
                        this.updateSelects();
                    } else {
                        console.error("Fehler:", data.message);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        });
    }




    updateSelects() {
        const selects = this.thead.querySelectorAll('select');
        const saveButton = document.createElement('button');
        saveButton.className = 'btn btn-primary btn-sm mt-3';
        saveButton.textContent = 'Importieren';
        saveButton.style.display = 'none';
        this.table.after(saveButton);
        
        const requiredValues = ['payment_reference', 'purpose', 'payment_date', 'amount'];

        selects.forEach(select => {
            select.addEventListener('change', () => {
                const selectedValues = Array.from(selects)
                    .map(s => s.value)
                    .filter(v => v !== '');
                                
                const duplicates = selectedValues.filter((value, index) => 
                    selectedValues.indexOf(value) !== index
                );
                
                if (duplicates.length > 0) {
                    select.value = '';
                    saveButton.style.display = 'none'; 
                    return;
                }
                
                // console.log('Länge selected:', selectedValues.length);
                // console.log('Länge selects:', selects.length);     

                const allRequiredSelected = requiredValues.every(value => 
                    selectedValues.includes(value)
                );
                
                console.log('Alle benötigten Werte ausgewählt?', allRequiredSelected);
                saveButton.style.display = allRequiredSelected ? 'block' : 'none';

                selects.forEach(s => {
                    Array.from(s.options).forEach(option => {
                        const shouldDisable = option.value && 
                                            selectedValues.includes(option.value) && 
                                            s.value !== option.value;
                        option.disabled = shouldDisable;
                    });
                });
            });
        });

        saveButton.addEventListener('click', () => {
            const mapping = {};
            const selectedData = [];
            
            selects.forEach((select, index) => {
                if(select.value !== '') {
                    mapping[index] = select.value;
                }
            });
        
            this.currentData.forEach(row => {
                const newRow = {};
                for(let index in mapping) {
                    if(mapping[index]) {
                        const column = Object.keys(row)[index];
                        newRow[mapping[index]] = row[column];
                    }
                }
                selectedData.push(newRow);
            });
            

            console.log(selectedData)
            fetch(transactionImport, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.importForm.querySelector('[name=csrfmiddlewaretoken]').value
                },
                body: JSON.stringify({
                    mapping: mapping,
                    data: selectedData
                })
            })
            .then(response => response.json())
            .then(data => {
                if(data.success) {
                    console.log(data)
                    this.showImportResults(data.summary);
                } else {
                    console.log('Fehler beim Import: ' + data.message);
                }
            });

        });
    }

    showImportResults(summary) {

        window.deleteTransaction = (id) => {
            console.log('RUN')
            fetch(`${transactionImport}delete/${id}/`, { 
                method: 'DELETE',
                headers: {
                    'X-CSRFToken': this.importForm.querySelector('[name=csrfmiddlewaretoken]').value
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(data.success) {
                    const row = document.querySelector(`tr[data-transaction-id="${id}"]`);
                    if (row) {
                        row.remove();
                    }
                }else {
                    console.error('Fehler beim Import:', data.message);
                }
            })
            .catch(error => {
                console.error('Fehler:', error);
            })
        };

        const resultDiv = document.createElement('div');
        resultDiv.className = 'import-results mt-4';

        resultDiv.innerHTML = `
            <h4>Import Ergebnisse</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Datum</th>
                        <th>Rechnungsnummer</th>
                        <th>Kunde</th>
                        <th>Referenz</th>
                        <th>Verwendungszweck</th>
                        <th>Betrag</th>
                        <th>Aktion</th>
                    </tr>
                </thead>
                <tbody>
                    ${summary.changed_invoices.map(inv =>`
                        <tr class="table-success" data-transaction-id="${inv.transaction_id}">
                            <td>Zugeordnet</td>
                            <td>${inv.payment_date}</td>
                            <td>${inv.invoice_number}</td>
                            <td>${inv.customer}</td>
                            <td>${inv.reference || '-'}</td>
                            <td>${inv.purpose || '-'}</td>
                            <td>${inv.amount} €</td>
                            <td>
                                <button class="btn btn-danger btn-sm" onclick="deleteTransaction(${inv.transaction_id})">
                                    Rückgängig
                                </button>
                            </td>
                        </tr>
                    `).join('')}
                    ${summary.unmatched_payments.map(payment => `
                        <tr class="table-warning">
                            <td>Nicht zugeordnet</td>
                            <td>${payment.payment_date}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>${payment.reference}</td>
                            <td>${payment.purpose}</td>
                            <td>${payment.amount} €</td>
                            <td>-</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
            <button class="btn btn-primary btn-sm mt-3" onclick="window.location.reload()">
                Neuer Import
            </button>
        `;

        const oldResults = document.querySelector('.import-results');
        if (oldResults) oldResults.remove();
        this.table.after(resultDiv);
    }
    


}
