
import importBanking from "./module/importBanking";
import createBanking from "./module/createBanking";
import bankingModalOpen from "./module/modalBanking";

document.addEventListener('DOMContentLoaded', function () {
    new createBanking()
    bankingModalOpen();

    const updateForm = document.getElementById('transaktionFormsUpdate');
    if(updateForm) {
        const formElements = updateForm.querySelectorAll('input, select, textarea');
        formElements.forEach(element => {
            element.disabled = true;
            element.classList.add('disabled');
        });

        const select2Elements = updateForm.querySelectorAll('.select2-hidden-accessible');
        select2Elements.forEach(element => {
            $(element).select2('destroy');
            element.disabled = true;
            $(element).select2({
                disabled: true
            });
        });
    }

    // Import
    const form = document.getElementById('importBankingForm');
    if(!form) return;
    const bankingInstance = new importBanking(form); 
    bankingInstance.init();
});