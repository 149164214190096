import { BaseAjaxSave } from "../common/shared_service";

export default class AmountWatcher {
    constructor(formSelector, fieldId) {
        this.form = document.querySelector(formSelector);
        this.field = document.getElementById(fieldId);
        this.lastValue = this.field ? this.field.value : null;

        if (this.field && this.form) {
            this.observeField();
        }
    }

    observeField() {
        setInterval(() => {
            const currentValue = this.field.value;
            if (currentValue !== this.lastValue) {
                this.lastValue = currentValue;
                this.saveForm();
            }
        }, 500);
    }

    saveForm() {
        BaseAjaxSave({
            target: this.form,
            preventDefault: () => {}
        });
    }
}
