// agriflow/dashboard/static/js/dashboard.js

import serviceStatistics from "./module/service";

document.addEventListener('DOMContentLoaded', function () {
    const paymentStatus = document.querySelector('#payment-status');
    if(!paymentStatus) return;
    serviceStatistics();
});

if (document.getElementById('myBarChart')) {
    const barCtx = document.getElementById('myBarChart').getContext('2d');
    new Chart(barCtx, {
        type: 'bar',
        data: {
            labels: ['Januar', 'Februar', 'März'],
            datasets: [{
                label: 'Beispiel-Daten',
                data: [12, 19, 3],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                y: { beginAtZero: true }
            }
        }
    });
}

if (document.getElementById('myPieChart')) {
    const pieCtx = document.getElementById('myPieChart').getContext('2d');
    new Chart(pieCtx, {
        type: 'pie',
        data: {
            labels: ['Rot', 'Blau', 'Grün'],
            datasets: [{
                label: 'Farben-Verteilung',
                data: [300, 50, 100],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(75, 192, 192, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            responsive: true,
            plugins: {
                legend: { position: 'top' },
                tooltip: { enabled: true }
            }
        }
    });
}


