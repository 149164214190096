

import AlertHandler from "../../../../assets/js/modules/alertHandler";


export default function passwordReset() {
    const resetForm = document.getElementById('password-reset-form');
    if (resetForm) {
        resetForm.addEventListener('submit', function(e) {
            e.preventDefault();
            const email = document.querySelector('#email').value;
            const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
            
            fetch('/accounts/password-reset/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify({
                    email: email
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    AlertHandler.showAlert(data.message, 'success');
                } else {
                    console.error(data.message || data.error);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    }

    const confirmForm = document.getElementById('password-reset-confirm-form');
    if (confirmForm) {
        confirmForm.addEventListener('submit', function(e) {
            e.preventDefault();
            
            const newPassword = document.querySelector('#new_password').value;
            const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
            const confirmPassword = document.querySelector('#new_password_confirm').value;
            const errorDiv = document.querySelector('#password-error');

            if (newPassword !== confirmPassword) {
                errorDiv.style.display = 'block';
                return;
            }
            
            errorDiv.style.display = 'none';

            fetch(window.location.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify({
                    new_password: newPassword
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    window.location.href = '/accounts/';
                } else {
                    console.error(data.message || data.error);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                console.error('Ein Fehler ist aufgetreten');
            });
        });
    }
}

// support@pixel-west.com

// f66a2ea2df4e7adaaa2dc211835d04e8