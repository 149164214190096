
const timers = {};

export function addTimer() {
    const original = document.querySelector('.timerSection');
    const clone = original.cloneNode(true);
    clone.querySelector(".timerDisplay").innerText = "00:00:00";
    clone.querySelector(".savedTimeDisplay").style.display = "none";
    const numberElement = clone.querySelector('.timerNumber');
    if (numberElement) {
        numberElement.remove();
    }
    const newId = Date.now().toString();
    clone.dataset.taskId = newId;
    document.getElementById('timerContainer').appendChild(clone);
}


export function stopTimer(button) {
    const timerSection = button.closest('.timerSection');
    const taskId = timerSection.dataset.taskId;
    const url = timerSection.dataset.urlOrder;
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if (timers[taskId]) {
        clearInterval(timers[taskId]);
        const display = timerSection.querySelector('.timerDisplay');
        const stoppedTime = display.textContent;
        const formData = new FormData();
        formData.append('action', 'stop');
        formData.append('time', stoppedTime);
        navigator.geolocation.getCurrentPosition((position) => {
            formData.append('latitude', position.coords.latitude);
            formData.append('longitude', position.coords.longitude);
            sendStopRequest(url, csrfToken, formData);
        }, () => {
            sendStopRequest(url, csrfToken, formData);
        });
        delete timers[taskId];
    }
}

function sendStopRequest(url, csrfToken, formData) {
    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
        },
        body: formData
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            console.log('Timer gestoppt');
        } else {
            console.error('Fehler:', data.error);
        }
    })
    .catch(error => {
        console.error('Anfragefehler:', error.message);
    });
}


function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export function checkExistingTimer(timerSection) {
    const url = timerSection.dataset.urlOrder;
    fetch(url, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then(response => response.json())
    .then(data => {

        data.forEach((timer, index) => {
            let currentTimerSection = timerSection.cloneNode(true);
            currentTimerSection.dataset.taskId = timer.id.toString();
            timerContainer.appendChild(currentTimerSection);
            
            const display = currentTimerSection.querySelector('.timerDisplay');
            const savedTimeDisplay = currentTimerSection.querySelector('.savedTimeDisplay');
            const savedTime = currentTimerSection.querySelector('.savedTime');

            const pauseButton = currentTimerSection.querySelector('.pauseTimer');
            const startButton = currentTimerSection.querySelector('.startTimer');
            const stopButton = currentTimerSection.querySelector('.stopTimer');
            const noteInput = currentTimerSection.querySelector('.noteInput');

            display.innerText = timer.duration;
            savedTime.textContent = timer.duration;

            if (timer.note) {
                noteInput.value = timer.note;
            }

            if (timer.status === 'Läuft') {
                const [hours, minutes, seconds] = timer.duration.split(':').map(Number);
                let totalSeconds = hours * 3600 + minutes * 60 + seconds;
                
                timers[timer.id] = setInterval(() => {
                    totalSeconds++;
                    display.innerText = formatTime(totalSeconds);
                }, 1000);

                pauseButton.textContent = 'Pause';
                startButton.disabled = true;
                stopButton.disabled = false;
                savedTimeDisplay.style.display = 'none';
            } else if (timer.status === 'Pausiert') {
                const [hours, minutes, seconds] = timer.duration.split(':').map(Number);
                let totalSeconds = hours * 3600 + minutes * 60 + seconds;
                display.innerText = formatTime(totalSeconds);
                savedTime.textContent = ''
                pauseButton.textContent = 'Fortsetzen';
                startButton.disabled = true;
                stopButton.disabled = false;
                savedTimeDisplay.style.display = 'none';

            } else { // Gestoppt
                const startTime = new Date(timer.start_time);
                const endTime = new Date(timer.end_time);
                const seconds = Math.floor((endTime - startTime) / 1000);
                display.innerText = formatTime(seconds);
                savedTime.textContent = formatTime(seconds);
                savedTimeDisplay.style.display = 'block';

                pauseButton.disabled = true;
                startButton.disabled = true;
                stopButton.disabled = true;
            }

        });
    })
    .catch(error => console.error('Fehler beim Abrufen der Timer:', error));
}


export function pauseTimer(button) {
    const timerSection = button.closest('.timerSection');
    const taskId = timerSection.dataset.taskId;
    const url = timerSection.dataset.urlOrder;
    const display = timerSection.querySelector('.timerDisplay');
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    const noteInput = timerSection.querySelector('.noteInput').value; 

    let totalSeconds = parseTime(display.innerText);

    const formData = new FormData();
    formData.append('action', 'pause');
    formData.append('note', noteInput);
    
    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
        },
        body: formData
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            if (data.paused) {
                button.textContent = 'Fortsetzen';
                clearInterval(timers[taskId]);
            } else {
                button.textContent = 'Pause';
                timers[taskId] = setInterval(() => {
                    totalSeconds++;
                    display.innerText = formatTime(totalSeconds);
                }, 1000);
            }
        } else {
            console.error('Fehler:', data.error);
        }
    })
    .catch(error => {
        console.error('Anfragefehler:', error.message);
    });
}


function parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}


export function startTimer(button) {
    console.log('startTimer wurde aufgerufen');
    const section = button.closest('.timerSection');
    const id = section.dataset.taskId;
    const display = section.querySelector('.timerDisplay');
    const url = section.dataset.urlOrder;
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    const note = section.querySelector('.noteInput').value;
    if (timers[id]) clearInterval(timers[id]);
    let seconds = 0;
    display.innerText = formatTime(seconds);    
    timers[id] = setInterval(() => { 
        seconds++; 
        display.innerText = formatTime(seconds); 
    }, 1000);
    const formData = new FormData();
    formData.append('action', 'start');
    formData.append('note', note);

    /* sendStartRequest(url, csrfToken, formData); */

    navigator.geolocation.getCurrentPosition(
        (position) => {
            formData.append('latitude', position.coords.latitude);
            formData.append('longitude', position.coords.longitude);
            sendStartRequest(url, csrfToken, formData);
        }, 
        () => sendStartRequest(url, csrfToken, formData),
        { timeout: 5000 } // Setzt ein Timeout von 5 Sekunden für die Standortabfrage
    );

    /* navigator.geolocation.getCurrentPosition((position) => {
        formData.append('latitude', position.coords.latitude);
        formData.append('longitude', position.coords.longitude);
        sendStartRequest(url, csrfToken, formData);
    }, () => {
        sendStartRequest(url, csrfToken, formData);
    }); */
   
}

/* function sendGeoUpdateRequest(url, csrfToken, formData) {
    console.log('Geodaten werden gesendet');
    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
        },
        body: formData
    })
    .then(response => response.text()) // Logge die gesamte Antwort als Text
    .then(responseText => {
        try {
            const data = JSON.parse(responseText);
            console.log('Antwort:', data);
        } catch (error) {
            console.error('Ungültige JSON-Antwort:', responseText);
        }
    })
    .catch(error => console.error('Anfragefehler:', error.message));
}
 */
function sendStartRequest(url, csrfToken, formData) {
    console.log('sendStartRequest wurde aufgerufen');
    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
        },
        body: formData
    })
    .then(response => {
        if (!response.ok) {
            return response.json().then(error => {
                throw new Error(`Serverantwort: ${response.status} - ${JSON.stringify(error)}`);
            });
        }
        return response.json();
    })
    .then(data => {
        if (data.success) {
            console.log('Timer erfolgreich gestartet');
        } else {
            console.error('Fehler:', data.error);
        }
    })
    .catch(error => {console.error('Anfragefehler:', error.message);});
}