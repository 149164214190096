import AlertHandler from '../../../../assets/js/modules/alertHandler'

export default class customersCreate{

    constructor(){
        this.events();
    }

    events(){
        const createCustomerFormSave = document.getElementById('customerCreate');
        if(createCustomerFormSave){
            createCustomerFormSave.addEventListener('submit', this.createCustomer.bind(this));
        }
    } 

    createCustomer(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    AlertHandler.showAlert(body.message, 'success');

                    if (body.id) {
                        setTimeout(() => {
                            window.location.href = `/customers/update/${body.id}/`;
                        }, 1000);
                    }
                } else {
                    console.log('Fehler beim Speichern!', body.errors || body.message);
                    AlertHandler.showAlert(body.message, 'danger');
                }
            } else {
                console.log('Server returned an error:', body.errors || body.message);
                AlertHandler.showAlert(body.message, 'danger');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });
    }




}