
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class SkontCreat{
    constructor(){
        this.SkontoForm = document.querySelector('#SkontoForm');
        if (!this.SkontoForm) return;
        this.events();
    }

    events(){
        this.SkontoForm.addEventListener('submit', (event) => {
            event.preventDefault();
            BaseAjaxSave(event);
        });
    }
}