
import AlertHandler from "../modules/alertHandler";

export function confirmBtn(url, ids) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': token,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            document.getElementById(ids.number).textContent = data.designation_number;
            document.getElementById(ids.confirmDate).textContent = data.date_confirmed;
            document.getElementById(ids.status).value = 'Bestätigt'; 
            AlertHandler.showAlert(data.message, 'success');
        } else{
            console.log('[CONFIRM] Fehler:', error);
            AlertHandler.showAlert(data.message, 'danger');
        }
    })
    .catch(error => {
        console.log('[CONFIRM] Fehler:', error);
        AlertHandler.showAlert("Ein unbekannter Fehler ist aufgetreten.", 'danger'); 
    }); 
}

