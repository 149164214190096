
import ModalsGeneric from "../../../../assets/js/common/ModalGeneric";

export default class ActivitiesCrate {
    constructor(formsUrl, buttonClass) {
        this.formsUrl = formsUrl;
        this.buttonClass = buttonClass;
        this.modalService = new ModalsGeneric();
        this.initializeModal();
        this.bindButtonEvents();
        this.addCloseEventListeners();
    }

    initializeModal() {
        this.modalService.init('#activitiesModal');
    }

    addCloseEventListeners() {
        document.querySelectorAll('.close-modal-button-activities').forEach(button => {
            button.addEventListener('click', () => {
                this.modalService.closeModal();
            });
        });
    }

    bindButtonEvents() {
        document.body.addEventListener('click', (event) => {
            if (event.target.classList.contains(this.buttonClass.replace('.', ''))) {
                this.modalService.loadModalData(this.formsUrl);
            }
        });
    }

    closeModal(){
        if (this.modalService) {
            this.modalInstance.hide();
        }
    }

}