

import GenericAPI from "../../../../assets/js/modules/GenericAPI";

export default class ActivitiesList{
    constructor(activitiesUrl, divElement){
        this.activitiesUrl = activitiesUrl;
        this.divElement = divElement;
        this.init();
    }
    async init(){
        const api = new GenericAPI(this.activitiesUrl);
        const data = await api.loadData();
        this.renderElements(data);
    }

    renderElements(data){
        const container = document.getElementById(this.divElement);
        container.innerHTML = '';
        const fieldDiv = document.createElement('div');
        fieldDiv.className = 'list-group border-0';
        data.activiti_data.forEach(item => {

            // activitiesEdit
            var editURL = activitiesEdit.replace(/0/, item.id);
            var editCompleteURL = activitiesCompleteEdit.replace(/0/, item.id);
            var deleteURL = activitiesDelete.replace(/0/, item.id);

            let content = `
                <div class="card mb-3">

                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div class="d-flex gap-3 align-items-center">
                            <span class="fs-7"><span class="text-body-secondary">Priorität:</span> <span class="badge rounded-pill text-bg-${item.priority_css}">${item.priority}</span></span> 
                            <span class="fs-7"><span class="text-body-secondary">Aufgabe:</span> ${item.title}</span>
                            <span class="fs-7"><span class="text-body-secondary">Zugewiesen an:</span> ${item.assigned_to}</span>
                            <span class="fs-7"><span class="text-body-secondary">Zu erledigen bis:</span> ${item.due_date} ${item.category ? item.category: ''}</span>
                        </div>
                        <div class="d-flex gap-3">
                            <div class="activities-button-edit" data-url="${editURL}">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div class="activities-button-delete" data-url="${deleteURL}">
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        ${item.description}
                    </div>

                    <div class="card-footer d-flex justify-content-between align-items-center">
                        <div class="d-flex gap-3 align-items-center">
                            <span class="text-body-secondary fs-7">Erstellt am:</span> <span class="fs-7">${item.date_created} </span>
                            <span class="text-body-secondary fs-7">Erledigt von:</span> <span class="fs-7">${item.completed_by ? item.completed_by: 'Nicht erledigt'}</span>
                            <span class="text-body-secondary fs-7">Erledigt am:</span> <span class="fs-7">${item.date_completed ? item.date_completed: '-'}</span>
                        </div>
                        <div class="d-flex gap-3">
                            <button data-url="${editCompleteURL}" type="button" class="btn btn-primary completed ${item.date_completed ? 'disabled': ''}" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"> Als erledigt markieren </button>
                        </div>
                    </div>
                </div>
            `;
            fieldDiv.innerHTML += content;
        });
        container.appendChild(fieldDiv);

    }
}