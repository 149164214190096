

import ActivitiesCrate from "./module/activitiesCreate";
import ActivitiesComplete from "./module/activitiesComplete";
import ActivitiesDelete from "./module/ActivitiesDelete";
import ActivitiesUpdate from "./module/ActivitiesUpdate";
import activitiesUser from "./module/ActivitiesUser";

document.addEventListener('DOMContentLoaded', function () {

    activitiesUser();

    const activityButton = document.querySelector('.activities-button');
    if (activityButton && activityButton.dataset.url) {
        const activitiesUrl = activityButton.dataset.url;
        const activitiescrate = new ActivitiesCrate(activitiesUrl, '.activities-button');
    }


    const observer = new MutationObserver((mutations, obs) => { 
        const activityButtonsEdit = document.querySelectorAll('.activities-button-edit');
        if(activityButtonsEdit.length > 0){
            obs.disconnect();
            const activitiesupdate = new ActivitiesUpdate('.activities-button-edit');
        }
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true
    });



    const completedButtonObserver = new MutationObserver((mutations, obs) => { 
        const completedButtons = document.querySelectorAll('.btn.completed');
        completedButtons.forEach(button => {
            button.addEventListener('click', () => {

                const completedBtn = new ActivitiesComplete('.btn.completed')
            });
        });
    });
    completedButtonObserver.observe(document.body, {
        childList: true,
        subtree: true
    });


    const deletedButtonObserver = new MutationObserver((mutations, obs) => { 
        const activityButtonsDelete = document.querySelectorAll('.activities-button-delete');
        if(activityButtonsDelete.length > 0){
            obs.disconnect();
            const activitiesdelete = new ActivitiesDelete('.activities-button-delete');
            activitiesdelete.bindButtonEvents(); 
        }
    });
    deletedButtonObserver.observe(document.body, {
        childList: true,
        subtree: true
    });

});