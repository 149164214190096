
import AlertHandler from '../../../../assets/js/modules/alertHandler';
import { Modal } from 'bootstrap';

let modalIsOpen = false;
function setupModalTrigger() {
    document.addEventListener('click', event => {
        const modalTrigger = event.target.closest('.openModalTimetracking');
        if (!modalTrigger) return;

        const modalUrl = modalTrigger.dataset.modalUrl;
        const modalElement = document.getElementById('TimetrackingModal');

        if (modalElement) {
            if (modalIsOpen) return;

            modalIsOpen = true;

            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }

            new Modal(modalElement).show();
            loadModalContent(modalUrl, modalElement);
            new TimetrackingHandler();

            modalElement.addEventListener('hidden.bs.modal', () => {
                modalIsOpen = false;
            }, { once: true });
        }
    });
}


export function DataAdminPannel() {
    const pannel = document.querySelector('#CoordinatesPannel');
    const url = pannel.dataset.urlOrder;
    fetch(url, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then(response => response.json())
    .then(data => {
        pannel.innerHTML = ''; 
        data.forEach((timer) => {
            const row = document.createElement('tr');
            var URL = TimetrackingUpdateCreateView.replace(/0/, timer.id);
            row.innerHTML = `
                <td>
                    <div class="openModalTimetracking" data-modal-url="${URL}">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </div>
                </td>
                <td>${new Date(timer.start_time).toLocaleTimeString()}</td>
                <td>${new Date(timer.end_time).toLocaleTimeString()}</td>
                <td>${timer.duration.split(':').slice(0, 2).map(Number).map(num => String(num).padStart(2, '0')).join(':')}</td>
                <td>${timer.pause_duration.split(':').slice(0, 2).map(Number).map(num => String(num).padStart(2, '0')).join(':')}</td>
                <td>${timer.status}</td>
                <td>${timer.note}</td>
            `;
            pannel.appendChild(row);

            /* document.addEventListener('click', event => {
                const modalTrigger = event.target.closest('.openModalTimetracking');
                if (!modalTrigger) return;
    
                const modalUrl = modalTrigger.dataset.modalUrl;
                const modalElement = document.getElementById('TimetrackingModal');
    
                if (modalElement) {
                    const modalInstance = Modal.getInstance(modalElement);
                    if (modalInstance) {
                        modalInstance.hide();
                    }
                    new Modal(modalElement).show();
                    loadModalContent(modalUrl, modalElement);
                    const timetracking = new TimetrackingHandler();
                }
            }); */

            setupModalTrigger();

        });
    })
    .catch(error => console.error('Fehler beim Abrufen der Timer:', error));

}


function loadModalContent(url, modalElement) {
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Daten');
            }
            return response.json();
        })
        .then(data => {
            const modalBody = modalElement.querySelector('.modal-body');
            const modalTitle = modalElement.querySelector('.modal-title');
            if (modalBody) {
                modalBody.innerHTML = data.html;
                new Modal(modalElement).show();
                modalTitle.textContent = data.title; 
                setTimeout(() => {
                    const mapContainer = document.getElementById('map');
                    
                    if (mapContainer._leaflet_id) {
                        mapContainer._leaflet_id = null;
                        mapContainer.innerHTML = '';
                    }
                    const map = L.map(mapContainer).setView([data.start_latitude, data.start_longitude], 13);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: 19,
                        attribution: '&copy; OpenStreetMap contributors'
                    }).addTo(map);

                    const startIcon = L.divIcon({
                        className: 'custom-marker',
                        iconSize: [20, 20]
                    });
                    L.marker([data.start_latitude, data.start_longitude], { icon: startIcon })
                        .addTo(map)
                        .bindPopup('Start Standort')
                        .openPopup();

                    if (data.end_latitude && data.end_longitude) {
                        const endIcon = L.divIcon({
                            className: 'custom-marker-end',
                            iconSize: [20, 20]
                        });
                        L.marker([data.end_latitude, data.end_longitude], { icon: endIcon })
                            .addTo(map)
                            .bindPopup('End Standort');
                    }
                }, 400);

                modalElement.addEventListener('hidden.bs.modal', () => {
                    const backdrops = document.querySelectorAll('.modal-backdrop');
                    backdrops.forEach(backdrop => backdrop.parentNode.removeChild(backdrop));
                    const modalInstance = Modal.getInstance(modalElement);
                    if (modalInstance) {
                        modalInstance.dispose();
                    }
                });

                /* setTimeout(() => {
                    const mapContainer = document.getElementById('map');
                    const map = L.map(mapContainer).setView([data.start_latitude, data.start_longitude], 13);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: 100,
                        attribution: '&copy; OpenStreetMap contributors'
                    }).addTo(map);

                    const startIcon = L.divIcon({
                        className: 'custom-marker',
                        iconSize: [20, 20]
                    });
                    L.marker([data.start_latitude, data.start_longitude], { icon: startIcon })
                        .addTo(map)
                        .bindPopup('Start Standort')
                        .openPopup();

                    if (data.end_latitude && data.end_longitude) {
                        const endIcon = L.divIcon({
                            className: 'custom-marker-end',
                            iconSize: [20, 20]
                        });
                        L.marker([data.end_latitude, data.end_longitude], { icon: endIcon })
                            .addTo(map)
                            .bindPopup('End Standort');
                    }
                }, 400); */
            } else {
                console.error('Modal body nicht gefunden');
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten beim Laden des Inhalts.', 'danger');
        });
}


export default class TimetrackingHandler {
    
    constructor() {
        this.observeDOMChanges();
    }

    addEvents(){
        element.querySelectorAll('.openModalServie').forEach(button => {
            button.addEventListener('click', event => {
                event.preventDefault();
                this.handleFormFound(node);
            });
        });
    }

    handleFormFound(form) {
        form.addEventListener('submit', this.updateTimetracking);
    }


    observeDOMChanges() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === 1 && node.matches('#TimetrackingForm')) {
                        this.handleFormFound(node);
                    }
                });
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    updateTimetracking(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;
        
        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    AlertHandler.showAlert(body.message, 'success');

                    const modalElement = document.getElementById('myModalServie1');
                    const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
                    modal.hide();

                    /* setTimeout(() => {
                        window.location.reload();
                    }, 300); */
                } else {
                    AlertHandler.showAlert(body.message, 'danger');
                }
            } else {
                AlertHandler.showAlert(body.message, 'danger');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });
    }
}
