// agriflow/accounts/static/js/accounts.js
import AccountsLogin from './modules/accountsLogin'
import AccountsCreate from './modules/accountsCreate'
import accountsUpdate from './modules/accountsUpdate'
import passwordReset from './modules/passwordReset';
import deaktiviertAccounts from './modules/accountsDeaktiviert';

document.addEventListener('DOMContentLoaded', function () {
    const accountslogin = new AccountsLogin();
    const accountscreate = new AccountsCreate();
    const accountsupdate = new accountsUpdate();
    new deaktiviertAccounts();
    
    passwordReset();
});
