// agriflow/customers/static/js/customers.js

import customersUpdate from './modles/customersUpdate'
import renderContact from './modles/renderContact'
import customersChangeHistory from './modles/customersChangeHistory'
import getNextNumerView from '../../../core/static/module/numberRange.js';
import customersCreate from './modles/customersCreate'
import CustomerImport from "./modles/importCustomers.js";

document.addEventListener('DOMContentLoaded', function () {
    const customerscreate = new customersCreate();
    const customersupdate = new customersUpdate();
    const rendercontact = new renderContact();
    const customerschangehistory = new customersChangeHistory();


    const uploadButton = document.getElementById('uploadButton');
    if (uploadButton) {
        uploadButton.addEventListener('click', (event) => {
            event.preventDefault();
            CustomerImport();
        });
    }

    if (typeof import_url !== 'undefined' && document.querySelector(".my-icon-class")) {
        const iconContainer = document.querySelector(".my-icon-class");
        const link = document.createElement("a");
        link.href = import_url;
        const icon = document.createElement("i");
        icon.classList.add("fa-solid", "fa-upload");
        link.appendChild(icon);
        iconContainer.appendChild(link);
    }


    const formObserver = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.addedNodes.length) {
                const titelSelect = document.querySelector('#id_titel');
                const companyNameField = document.querySelector('#id_company_name');                
                if (titelSelect) {
                    companyNameField.parentElement.parentElement.style.display = 'none';
                    
                    titelSelect.addEventListener('change', function() {
                        if (this.value === 'Firma') {
                            companyNameField.parentElement.parentElement.style.display = 'block';
                        } else {
                            companyNameField.parentElement.parentElement.style.display = 'none';
                        }
                    });
                }
            }
        });
    });
    
    formObserver.observe(document.body, {
        childList: true,
        subtree: true
    });
    
    const createCustomerForm = document.getElementById('customerCreate');
    if(!createCustomerForm) return;
    getNextNumerView('KUN');






});