

import AlertHandler from "../../../../assets/js/modules/alertHandler";

export default class ActivitiesComplete{
    constructor(buttonClass){
        this.buttonClass = buttonClass;
        this.bindButtonEvents();
        this.addCloseEventListeners();
    }
    getMetaCSRFToken() {
        return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
    bindButtonEvents() {
        document.body.addEventListener('click', (event) => {
            const button = event.target.closest(this.buttonClass);
            if (button && button.dataset.url && !button.classList.contains('processing')) {
                button.classList.add('disabled');
                const activitiesUrl = button.dataset.url;
                const csrfToken = this.getMetaCSRFToken();
                console.log(csrfToken)
                fetch(activitiesUrl, {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': csrfToken,
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                .then(response => {
                    if (!response.ok) throw new Error(response.status);
                    return response.json();
                })
                .then(data => {

                    AlertHandler.showAlert(data.message, 'success');
                    if (typeof window.reloadActivitiesList === 'function') {
                        window.reloadActivitiesList();
                    }
                })
                .catch(error => {
                    console.error(error);
                    AlertHandler.showAlert(errorMessage || data.message || 'Unbekannter Fehler', 'danger');
                })
            }
        });
    }
    addCloseEventListeners() {
        document.querySelectorAll('.close-modal-button-activities').forEach(button => {
            button.addEventListener('click', () => {
                this.modalService.closeModal();
            });
        });
    }
    closeModal(){
        if (this.modalService) {
            this.modalInstance.hide();
        }
    }
}