
import AlertHandler from "../../../../assets/js/modules/alertHandler";

export default class AccountsLogin{

    constructor() {
        this.events();
    }

    events() {
        const form = document.getElementById('loginForm');
        if (form) {
            form.addEventListener('submit', this.login.bind(this));
        }
    }


    setCookie(name, value, days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }


    async login(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        try {
            const response = await fetch(form.action, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const result = await response.json();
                this.setCookie('access_token', result.access_token, 1);
                this.setCookie('refresh_token', result.refresh_token, 7);
 
                window.location.href = dashboardView;
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData.message);
                AlertHandler.showAlert(errorData.message || 'Unbekannter Fehler', 'danger');
            }
        } catch (error) {
            console.error('Error:', error);
            AlertHandler.showAlert(errorData.message || 'Unbekannter Fehler', 'danger');
        }

    }


}