

export default class GpsRouteView {
    constructor() {
        this.map = null;
        this.objectId = null;
        this.totalDistance = 0;
        this.allPoints = [];
    }

    init(id) {
        this.objectId = id;
        this.initMap();
        this.loadAndDisplayRouteDetails();
        this.addCalculateAreaButton();
    }

    initMap() {
        this.map = L.map('mapRouteView').setView([0, 0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadAndDisplayRouteDetails() {
        fetch(`/gpstracking/detail-template-route/${this.objectId}/`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => {
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            data.forEach(route => {
                const routeDistance = this.displayRoute(route);
                this.totalDistance += routeDistance;
            });
            this.displayTotalDistance();
        })
        .catch(error => {
            console.error('Fehler beim Laden der Routendaten:', error);
            this.displayError(error.message);
        });
    }

    displayRoute(route) {
        const path = route.gps_data.map(point => [
            parseFloat(point.latitude),
            parseFloat(point.longitude)
        ]);

        this.allPoints = this.allPoints.concat(path);

        L.polyline(path, {color: 'red'}).addTo(this.map);

        if (path.length > 0) {
            this.map.fitBounds(L.latLngBounds(path));
        }

        let distance = 0;
        for (let i = 1; i < path.length; i++) {
            distance += this.map.distance(path[i-1], path[i]);
        }

        if (path.length > 0) {
            L.marker(path[0]).addTo(this.map).bindPopup(`Start: ${route.name}<br>Länge: ${(distance/1000).toFixed(2)} km`);
            L.marker(path[path.length - 1]).addTo(this.map).bindPopup(`Ende: ${route.name}`);
        }

        return distance;
    }

    displayTotalDistance() {
        const distanceDiv = document.createElement('div');
        distanceDiv.style.padding = '10px';
        distanceDiv.style.backgroundColor = '#f0f0f0';
        distanceDiv.style.marginTop = '10px';
        distanceDiv.textContent = `Gesamtlänge aller Routen: ${(this.totalDistance/1000).toFixed(2)} km`;
        document.getElementById('mapRouteView').after(distanceDiv);
    }

    addCalculateAreaButton() {
        const button = document.createElement('button');
        button.textContent = 'Fläche berechnen';
        button.style.marginTop = '10px';
        button.classList = 'btn btn-primary'
        button.onclick = () => this.calculateAndDisplayArea();
        document.getElementById('mapRouteView').after(button);
    }

    calculateAndDisplayArea() {
        if (this.allPoints.length < 3) {
            alert('Nicht genug Punkte für eine Flächenberechnung');
            return;
        }
    
        // Entferne vorhandene Flächenberechnung, falls vorhanden
        const existingAreaDiv = document.getElementById('area-calculation');
        if (existingAreaDiv) {
            existingAreaDiv.remove();
        }
    
        const closedPolygon = [...this.allPoints, this.allPoints[0]];
        const polygon = L.polygon(closedPolygon, {color: 'blue', fillOpacity: 0.1}).addTo(this.map);
        
        // Berechne die Fläche des Polygons
        const latlngs = polygon.getLatLngs()[0];
        let area = 0;
        for (let i = 0; i < latlngs.length - 1; i++) {
            const p1 = latlngs[i],
                  p2 = latlngs[i + 1];
            area += (p2.lng - p1.lng) * (2 + Math.sin(p1.lat * Math.PI / 180) + Math.sin(p2.lat * Math.PI / 180));
        }
        area = Math.abs(area * 6378137 * 6378137 / 2);
        let areaInHectares = Math.round(area / 10000);
    
        // Formatiere die Zahl für bessere Lesbarkeit
        let formattedArea;
        if (areaInHectares >= 1000000) {
            formattedArea = (areaInHectares / 1000000).toFixed(2) + ' Mio.';
        } else if (areaInHectares >= 1000) {
            formattedArea = (areaInHectares / 1000).toFixed(2) + ' Tsd.';
        } else {
            formattedArea = areaInHectares.toString();
        }
    
        const areaDiv = document.createElement('div');
        areaDiv.id = 'area-calculation';
        areaDiv.style.padding = '10px';
        areaDiv.style.backgroundColor = '#e6f7ff';
        areaDiv.style.marginTop = '10px';
        areaDiv.textContent = `Ungefähre Fläche: ${formattedArea} Hektar`;
        document.getElementById('mapRouteView').after(areaDiv);

    }
    

    displayError(message) {
        const errorDiv = document.createElement('div');
        errorDiv.style.color = 'red';
        errorDiv.style.padding = '10px';
        errorDiv.textContent = `Fehler: ${message}`;
        document.getElementById('mapRouteView').appendChild(errorDiv);
    }
}

/* export default class GpsRouteView {
    constructor() {
        this.map = null;
        this.objectId = null;
        this.totalArea = 0;
    }

    init(id) {
        this.objectId = id;
        this.initMap();
        this.loadAndDisplayRouteDetails();
    }

    initMap() {
        this.map = L.map('mapRouteView').setView([0, 0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadAndDisplayRouteDetails() {
        fetch(`/gpstracking/detail-template-route/${this.objectId}/`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => {
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            let totalDistance = 0;
            data.forEach(route => {
                const { distance, area } = this.displayRoute(route);
                totalDistance += distance;
                this.totalArea += area;
            });
            this.displayTotalDistance(totalDistance);
            this.displayTotalArea();
        })
        .catch(error => {
            console.error('Fehler beim Laden der Routendaten:', error);
            this.displayError(error.message);
        });
    }

    displayRoute(route) {
        const path = route.gps_data.map(point => [
            parseFloat(point.latitude),
            parseFloat(point.longitude)
        ]);

        L.polyline(path, {color: 'red'}).addTo(this.map);

        if (path.length > 0) {
            this.map.fitBounds(L.latLngBounds(path));
        }

        let distance = 0;
        for (let i = 1; i < path.length; i++) {
            distance += this.map.distance(path[i-1], path[i]);
        }

        const area = distance * 3;

        if (path.length > 0) {
            L.marker(path[0]).addTo(this.map).bindPopup(`Start: ${route.name}<br>Länge: ${(distance/1000).toFixed(2)} km<br>Fläche: ${(area/10000).toFixed(2)} ha`);
            L.marker(path[path.length - 1]).addTo(this.map).bindPopup(`Ende: ${route.name}`);
        }

        return { distance, area };
    }

    displayTotalDistance(distance) {
        const distanceDiv = document.createElement('div');
        distanceDiv.style.padding = '10px';
        distanceDiv.style.backgroundColor = '#f0f0f0';
        distanceDiv.style.marginTop = '10px';
        distanceDiv.textContent = `Gesamtlänge aller Routen: ${(distance/1000).toFixed(2)} km`;
        document.getElementById('mapRouteView').after(distanceDiv);
    }

    displayTotalArea() {
        const areaDiv = document.createElement('div');
        areaDiv.style.padding = '10px';
        areaDiv.style.backgroundColor = '#e6f7ff';
        areaDiv.style.marginTop = '10px';
        areaDiv.textContent = `Gesamtfläche bearbeitet: ${(this.totalArea/10000).toFixed(2)} Hektar`;
        document.getElementById('mapRouteView').after(areaDiv);
    }

    displayError(message) {
        const errorDiv = document.createElement('div');
        errorDiv.style.color = 'red';
        errorDiv.style.padding = '10px';
        errorDiv.textContent = `Fehler: ${message}`;
        document.getElementById('mapRouteView').appendChild(errorDiv);
    }
} */











/* export default class GpsRouteView {
    constructor() {
        this.map = null;
        this.objectId = null;
        this.allPoints = [];
    }

    init(id) {
        this.objectId = id;
        this.initMap();
        this.loadAndDisplayRouteDetails();
    }

    initMap() {
        this.map = L.map('mapRouteView').setView([0, 0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadAndDisplayRouteDetails() {
        fetch(`/gpstracking/detail-template-route/${this.objectId}/`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => {
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            let totalDistance = 0;
            data.forEach(route => {
                const routeDistance = this.displayRoute(route);
                totalDistance += routeDistance;
            });
            this.displayTotalDistance(totalDistance);
            this.calculateAndDisplayArea();
        })
        .catch(error => {
            console.error('Fehler beim Laden der Routendaten:', error);
            this.displayError(error.message);
        });
    }

    displayRoute(route) {
        const path = route.gps_data.map(point => [
            parseFloat(point.latitude),
            parseFloat(point.longitude)
        ]);

        this.allPoints = this.allPoints.concat(path);

        L.polyline(path, {color: 'red'}).addTo(this.map);

        if (path.length > 0) {
            this.map.fitBounds(L.latLngBounds(path));
        }

        // Berechne die Streckenlänge
        let distance = 0;
        for (let i = 1; i < path.length; i++) {
            distance += this.map.distance(path[i-1], path[i]);
        }

        // Füge Marker für Start- und Endpunkt hinzu
        if (path.length > 0) {
            L.marker(path[0]).addTo(this.map).bindPopup(`Start: ${route.name}<br>Länge: ${(distance/1000).toFixed(2)} km`);
            L.marker(path[path.length - 1]).addTo(this.map).bindPopup(`Ende: ${route.name}`);
        }

        return distance;
    }

    displayTotalDistance(distance) {
        const distanceDiv = document.createElement('div');
        distanceDiv.style.padding = '10px';
        distanceDiv.style.backgroundColor = '#f0f0f0';
        distanceDiv.style.marginTop = '10px';
        distanceDiv.textContent = `Gesamtlänge aller Routen: ${(distance/1000).toFixed(2)} km`;
        document.getElementById('mapRouteView').after(distanceDiv);
    }

    calculateAndDisplayArea() {
        if (this.allPoints.length < 3) {
            console.log('Nicht genug Punkte für eine Flächenberechnung');
            return;
        }

        // Schließe das Polygon, indem der erste Punkt am Ende wiederholt wird
        const closedPolygon = [...this.allPoints, this.allPoints[0]];

        const polygon = L.polygon(closedPolygon, {color: 'blue', fillOpacity: 0.1}).addTo(this.map);
        const area = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0]);

        const areaDiv = document.createElement('div');
        areaDiv.style.padding = '10px';
        areaDiv.style.backgroundColor = '#e6f7ff';
        areaDiv.style.marginTop = '10px';
        areaDiv.textContent = `Ungefähre Fläche: ${(area / 10000).toFixed(2)} Hektar`;
        document.getElementById('mapRouteView').after(areaDiv);
    }

    displayError(message) {
        const errorDiv = document.createElement('div');
        errorDiv.style.color = 'red';
        errorDiv.style.padding = '10px';
        errorDiv.textContent = `Fehler: ${message}`;
        document.getElementById('mapRouteView').appendChild(errorDiv);
    }
} */



















/* export default class GpsRouteView {
    constructor() {
        this.map = null;
        this.objectId = null;
    }

    init(id) {
        this.objectId = id;
        this.initMap();
        this.loadAndDisplayRouteDetails();
    }

    initMap() {
        this.map = L.map('mapRouteView').setView([0, 0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadAndDisplayRouteDetails() {
        fetch(`/gpstracking/detail-template-route/${this.objectId}/`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => {
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            let totalDistance = 0;
            data.forEach(route => {
                const routeDistance = this.displayRoute(route);
                totalDistance += routeDistance;
            });
            this.displayTotalDistance(totalDistance);
        })
        .catch(error => {
            console.error('Fehler beim Laden der Routendaten:', error);
            this.displayError(error.message);
        });
    }

    displayRoute(route) {
        const path = route.gps_data.map(point => [
            parseFloat(point.latitude),
            parseFloat(point.longitude)
        ]);

        L.polyline(path, {color: 'red'}).addTo(this.map);

        if (path.length > 0) {
            this.map.fitBounds(L.latLngBounds(path));
        }

        // Berechne die Streckenlänge
        let distance = 0;
        for (let i = 1; i < path.length; i++) {
            distance += this.map.distance(path[i-1], path[i]);
        }

        // Füge Marker für Start- und Endpunkt hinzu
        if (path.length > 0) {
            L.marker(path[0]).addTo(this.map).bindPopup(`Start: ${route.name}<br>Länge: ${(distance/1000).toFixed(2)} km`);
            L.marker(path[path.length - 1]).addTo(this.map).bindPopup(`Ende: ${route.name}`);
        }

        return distance;
    }

    displayTotalDistance(distance) {
        const distanceDiv = document.createElement('div');
        distanceDiv.style.padding = '10px';
        distanceDiv.style.backgroundColor = '#f0f0f0';
        distanceDiv.style.marginTop = '10px';
        distanceDiv.textContent = `Gesamtlänge aller Routen: ${(distance/1000).toFixed(2)} km`;
        document.getElementById('mapRouteView').after(distanceDiv);
    }

    displayError(message) {
        const errorDiv = document.createElement('div');
        errorDiv.style.color = 'red';
        errorDiv.style.padding = '10px';
        errorDiv.textContent = `Fehler: ${message}`;
        document.getElementById('mapRouteView').appendChild(errorDiv);
    }
}
 */




















/* export default class GpsRouteView{
    constructor(){
        this.map = null;
        this.objectId = null;
    }

    init(id){
        this.objectId = id;
        this.initMap();
        this.loadAndDisplayRouteDetails();
    }

    initMap() {
        this.map = L.map('mapRouteView').setView([0, 0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadAndDisplayRouteDetails() {
        fetch(`/gpstracking/detail-template-route/${this.objectId}/`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            data.forEach(route => this.displayRoute(route));
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    displayRoute(route) {
        const path = route.gps_data.map(point => [
            parseFloat(point.latitude),
            parseFloat(point.longitude)
        ]);

        L.polyline(path, {color: 'red'}).addTo(this.map);

        if (path.length > 0) {
            this.map.fitBounds(L.latLngBounds(path));
        }

        // Füge Marker für Start- und Endpunkt hinzu
        if (path.length > 0) {
            L.marker(path[0]).addTo(this.map).bindPopup('Start: ' + route.name);
            L.marker(path[path.length - 1]).addTo(this.map).bindPopup('Ende: ' + route.name);
        }
    }
} */


