

import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";


export default class createBanking{

    constructor(){
        this.invoiceCreateForm = document.querySelector('#bankingFormCreate');
        if (!this.invoiceCreateForm) return;
        this.events();
    }

    events(){
        this.invoiceCreateForm.addEventListener('submit', (event) => {
            event.preventDefault();
            BaseAjaxSave(event);
        });
    }

}