

export default class SignatureForms{
    constructor(formId){
        const form = document.getElementById(`${formId}`);
        const signatureImage = document.getElementById('signature_image');
        
        if (form && signatureImage) {
            if (signatureImage.getAttribute('src')) {
                form.style.display = 'none';
                signatureImage.style.display = 'block';
            } else {
                form.style.display = 'block';
                signatureImage.style.display = 'none';
            }
        }
    }

    init(formId){
        const btnForm = document.getElementById(formId);
        if(btnForm) {
            btnForm.addEventListener('submit', this.formSave.bind(this))
        }
    }

    formSave(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;
        const action = form.action;
        const method = form.method;

        if (signaturePad.isEmpty()) {
            alert('Bitte unterschreiben Sie zuerst.');
        } else {
            var signatureData = signaturePad.toDataURL();
            formData.append('signature', signatureData);

            fetch(action, {
                method: method,
                body: formData,
                headers: {
                    'X-CSRFToken': csrfToken
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    location.reload();
                } else {
                    console.log('Fehler Signature: ' + data.message)
                }
            })
            .then(htmlText => {
                console.log(htmlText)
            })
            .catch(error => {
                console.error('Fetch Error:', error);
            });
        }
    }
}