

import RenderPricelist from "./module/renderPricelist";

document.addEventListener('DOMContentLoaded', function () {

    const conatiner = document.getElementById('CustomerPricelist');
    if(!conatiner) return;
    const renderpricelist = new RenderPricelist(); 
    window.reloadPriceList = async function() {
        await renderpricelist.init(); 
    };

});