
import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';

export default class customersChangeHistory {
    constructor() {
        this.event();
    }

    event() {
        const changeHistoryContainer = document.querySelector('#change-history-container');
        if (changeHistoryContainer) {
            const modelName = 'customers';
            const objectId = customersId;
            const fieldsToShow = ['designation_number', 'address_1', 'address_2', 'postcode', 'state', 'country', 'city', 'email', 'phone', 'tax_id'];
            const fieldLabels = {
                customer_number: 'Kundennummer',
                address_1: 'Adresse 1',
                address_2: 'Adresse 2',
                postcode: 'Postleitzahl',
                state: 'Bundesland',
                country: 'Land',
                city: 'Stadt',
                email: 'E-Mail-Adresse',
                phone: 'Telefonnummer',
                tax_id: 'Steuer-ID'
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container', fieldsToShow, fieldLabels);
        }
    }
}