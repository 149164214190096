

import { Modal } from 'bootstrap';
import AlertHandler from '../../../../assets/js/modules/alertHandler';

export default class ActivitiesDelete {
    constructor(buttonClass) {
        this.buttonClass = buttonClass;
        this.initializeModal();
        this.bindButtonEvents();
    }

    initializeModal() {
        this.modalElement = document.querySelector('#genericModal');
        this.modal = new Modal(this.modalElement);
    }

    bindButtonEvents() {
        document.body.addEventListener('click', async (event) => {
            const button = event.target.closest(this.buttonClass);
            if (button && button.dataset.url) {
                const response = await fetch(button.dataset.url);
                const data = await response.json();
                
                this.modalElement.querySelector('.modal-content').innerHTML = data.html;
                this.modal.show();
                
                const form = this.modalElement.querySelector('form');
                if (form) {
                    form.setAttribute('action', button.dataset.url);
                    form.addEventListener('submit', this.handleSubmit.bind(this));
                }
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        
        const response = await fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRFToken': form.querySelector('[name=csrfmiddlewaretoken]').value
            }
        });
        const data = await response.json();
        if (data.success) {
            this.modal.hide();
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
            if (typeof window.reloadActivitiesList === 'function') {
                window.reloadActivitiesList();
            }
        } else {
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
        }
    }
}