import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';

export default class ordersChangeHistory {
    constructor(){
        this.changeHistoryContainer = document.querySelector('#change-history-container-orders');
        if(!this.changeHistoryContainer) return;
        this.event();
        
    }
    event(){
        if (this.changeHistoryContainer) {
            const modelName = 'orders';
            const objectId = ordersIdVar;
            const fieldsToShow = ['designation_number', 'status', 'amount'];
            const fieldLabels = {
                designation_number: 'Bezeichnungsnummer',
                status: 'Status',
                amount: 'Betrag'
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-orders', fieldsToShow, fieldLabels);
        }
    }
}