import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
import 'datatables.net-dt'; 
import 'datatables.net-dt/css/jquery.dataTables.css';  

export default function serviceStatistics() {
    $('#daterange').daterangepicker({
        locale: {
            format: 'DD.MM.YYYY',
            applyLabel: 'Übernehmen',
            cancelLabel: 'Abbrechen',
            fromLabel: 'Von',
            toLabel: 'Bis',
            customRangeLabel: 'Benutzerdefiniert',
            daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
            ],
            firstDay: 1
        },
        ranges: {
            'Heute': [moment(), moment()],
            'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Letzte 7 Tage': [moment().subtract(6, 'days'), moment()],
            'Letzte 30 Tage': [moment().subtract(29, 'days'), moment()],
            'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
            'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Dieses Jahr': [moment().startOf('year'), moment().endOf('year')],
            'Letztes Jahr': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        },
    }, function(start, end, label) {
        let period = 'month';
        if (label === 'Heute' || label === 'Gestern' || label === 'Letzte 7 Tage' || label === 'Letzte 30 Tage') {
            period = 'day';
        } else if (label === 'Dieses Jahr' || label === 'Letztes Jahr') {
            period = 'year';
        }
        const status = $('#payment-status').val() || 'Bezahlt';
        
        fetchDashboardData(
            start.format('YYYY-MM-DD'),
            end.format('YYYY-MM-DD'),
            period,
            status
    );
    }).on('apply.daterangepicker', function(ev, picker) {
        console.log('Range ausgewählt:', picker.startDate.format('DD.MM.YYYY'), picker.endDate.format('DD.MM.YYYY'));
        $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
    });

    $('#filter-button').on('click', function() {
        const dates = $('#daterange').data('daterangepicker');
        const status = $('#payment-status').val() || 'Bezahlt';
        const period = dates.ranges[dates.chosenLabel]?.period || 'month';
        
        fetchDashboardData(
            dates.startDate.format('YYYY-MM-DD'),
            dates.endDate.format('YYYY-MM-DD'),
            period,
            status
        );
    });

    const start = moment().startOf('month');
    const end = moment().endOf('month');
    $('#selected-date').val(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
    fetchDashboardData(
        start.format('YYYY-MM-DD'),
        end.format('YYYY-MM-DD'),
        'month',
        'Bezahlt'
    );
}


async function fetchDashboardData(startDate, endDate, period, paymentStatus) {
    try {
        const endDateObj = new Date(endDate);
        endDateObj.setDate(endDateObj.getDate() + 1);
        const newEndDate = endDateObj.toISOString().split('T')[0]; 
        const url = `/api/dashboard/invoices/?start_date=${startDate}&end_date=${newEndDate}&period=${period}&payment_status=${paymentStatus}`;
        const response = await fetch(url);
        const data = await response.json();
        // Update-Funktionen aufrufen
        updateStatistics(data.statistics);
        updatePositions(data.statistics.positions);
        updateCustomers(data.statistics.customer_groups);
        updateInvoices(data.invoices);
    } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
    }
}

function updateStatistics(statistics) {
    ['total', 'average_per_invoice', 'average_per_customer'].forEach(key => {
        const change = parseFloat(statistics[key].change.replace(',', '.').replace('%', ''));
        const changeClass = change > 0 ? 'text-success' : change < 0 ? 'text-danger' : 'text-muted';
        const arrow = change > 0 ? '↑' : change < 0 ? '↓' : '→';
        
        document.getElementById(key).innerHTML = `
            <div class="d-flex justify-content-between align-items-center">
                <span class="">${statistics[key].current}</span>
                <span class="ms-2 ${changeClass} ">
                    ${arrow} ${statistics[key].change}
                    <small class="text-muted ms-1 fs-6">(${statistics[key].previous})</small>
                </span>
            </div>
        `;
    });
}


function updatePositions(positions) {
    const sortedCategories = Object.entries(positions.service.categories)
        .sort((a, b) => {
            const amountA = parseFloat(a[1].current.amount.replace(' €', '').replace('.', '').replace(',', '.'));
            const amountB = parseFloat(b[1].current.amount.replace(' €', '').replace('.', '').replace(',', '.'));
            return amountB - amountA;  // Absteigend sortieren
        });
    
    let html = '<ol class="list-group list-group-numbered">';
    sortedCategories.forEach(([name, data]) => {
        html += `
            <li class="list-group-item d-flex justify-content-between">
                <span class="ms-2 me-auto">${name}</span>
                <span class="ms-auto me-3">${data.current.amount}</span>
                <span>${data.current.percentage}</span>
                <small class="text-muted ms-2">(${data.previous.amount})</small>
            </li>`;
    });
    html += '</ol>';
    document.getElementById('service-categories').innerHTML = html;
}


function updateCustomers(customerGroups) {
    const groupNames = {
        'top_3_percent': 'Top 3%',
        'top_56_percent': 'Top 56%',
        'others': 'Andere'
    };
    
    let groupHtml = '<ol class="list-group list-group-numbered mb-3">';
    ['top_3_percent', 'top_56_percent', 'others'].forEach(key => {
        const group = customerGroups[key];
        groupHtml += `
            <li class="list-group-item d-flex justify-content-between">
                <span class="ms-2 me-auto">${groupNames[key]}</span>
                <span class="ms-auto me-3">${group.current.amount}</span>
                <span>${group.current.percentage}</span>
                <small class="text-muted ms-2">(${group.previous.amount})</small>
            </li>`;
    });
    groupHtml += '</ol>';
    
    let customerHtml = '<ol class="list-group list-group-numbered">';
    ['top_3_percent', 'top_56_percent', 'others'].forEach(key => {
        customerGroups[key].current.customers.forEach(customer => {
            customerHtml += `
                <li class="list-group-item d-flex justify-content-between">
                    <span class="ms-2 me-auto">${customer.name}</span>
                    <span class="ms-auto me-3">${customer.amount}</span>
                    <span>${customer.invoice_count} Rechnungen</span>
                </li>`;
        });
    });
    customerHtml += '</ol>';
    
    document.getElementById('customer-groups').innerHTML = groupHtml;
    document.getElementById('customer-list').innerHTML = customerHtml;
}

function updateInvoices(invoices) {
    const data = invoices.map(invoice => [
        invoice.designation_number,
        new Date(invoice.date_confirmed).toLocaleDateString(),
        invoice.payment_status,
        invoice.amount
    ]);

    $('#invoices-table').DataTable({
        data: data,
        columns: [
            { title: "Nummer" },
            { title: "Datum" },
            { title: "Status" },
            { title: "Betrag" }
        ],
        order: [[3, 'desc']],
        destroy: true,
        searching: false,
        paging: false,
        info: false,
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            "paginate": {
                "first":      "Erste",
                "last":       "Letzte",
                "next":       "Nächste",
                "previous":   "Vorherige"
            }
        }
    });

}

