

export default class Sidebar {
    constructor(containerElement){
        this.container = containerElement;
        this.closeClass = document.querySelector('.sidebar-close')
        this.sidebarThemplatesOverlay = document.querySelector('.sidebar-themplates-overlay')
    }
    init(){
        if (!this.container) return;
        this.container.addEventListener('click', () => {
            this.openSidebar(); 
        });

        this.closeClass.addEventListener('click', () => {
            this.closeSidebar();
        });
    }

    openSidebar(){
        document.body.classList.add('overlay-active');
        setTimeout(() => {
            this.sidebarThemplatesOverlay.classList.add('active');
        }, 10);
    }

    closeSidebar(){
        document.body.classList.remove('overlay-active');
        this.sidebarThemplatesOverlay.classList.remove('active');
    }

}