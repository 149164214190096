

import ModalsGeneric from "../../../../assets/js/common/ModalGeneric";

export default function produktsUpdateModalOpen(){
    const tableContainer = document.querySelector('#productDetailContainer');
    if(!tableContainer) return;

    const modalService = new ModalsGeneric(); 
    modalService.init('#productsModal')

    const handleRowClick = (event) => {
        const clickedRow = event.target.closest('.produkt-update');
        if (clickedRow) {
            const productId = clickedRow.dataset.id;
            const url = productsUpdate.replace('0', productId);
            modalService.loadModalData(url); 
        }
    };

    const observer = new MutationObserver(() => {
        tableContainer.querySelectorAll('.produkt-update').forEach(row => {
            row.addEventListener('click', handleRowClick);
        });
    });
    observer.observe(tableContainer, { childList: true, subtree: true });


    document.querySelectorAll('.close-modal-button-products').forEach(button => {
        button.addEventListener('click', () => {
            modalService.closeModal();
        });
    }); 
}


