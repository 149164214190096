

export default function checkReferences(appLabel, modelName, objectId){
    const deleteButton = document.getElementById("delete-button");
    fetch(`/shared_service/check/${appLabel}/${modelName}/${objectId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk-Antwort war nicht ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data)
            if (Object.keys(data.references).length > 0) {
                deleteButton.href = '#';
                deleteButton.classList.add('disabled');
                deleteButton.textContent = 'Löschen nicht möglich';

/*                 const infoDiv = document.createElement('div');
                infoDiv.className = 'text-danger mt-2';
                infoDiv.innerHTML = `<small>Verknüpft mit: ${Object.entries(data.references).map(([key, count]) => `${key} (${count})`).join(', ')}</small>`;
                deleteButton.parentNode.insertBefore(infoDiv, deleteButton.nextSibling); */
            }
        })
        .catch(error => {
            console.error('Fehler beim Abrufen der Referenzen:', error);
        });
}
