
import  { ChangeHistory } from '../../../../assets/js/modules/changeHistory';

export default class machinerysChangeHistory {

    constructor() {
        this.changeHistoryContainerMachinerys = document.getElementById('change-history-container-machinerys');
        if(!this.changeHistoryContainerMachinerys) return;
        this.event();
    }

    event(){
        if(!this.changeHistoryContainerMachinerys) return;
        const modelName = 'machinerys';
        const objectId = machineryId;
        const fieldsToShow = ['designation_number', 'name', 'designation', 'unit', 'status', 'cost_per_unit'];
        const fieldLabels = {
            designation_number: 'Bezeichnungsnummer',
            name: 'Name',
            designation: 'Bezeichnung',
            cost_per_unit: 'Kosten pro Einheit',
            unit: 'Einheit',
            status: 'Status',
        };
        const changeHistory = new ChangeHistory(modelName, objectId);
        changeHistory.renderChanges('change-history-container-machinerys', fieldsToShow, fieldLabels);
    }


}