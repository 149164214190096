
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class PaymentTerms{

    constructor(){
        this.PaymentTermsCreateForm = document.querySelector('#PaymentTermsCreateForm');
        if (!this.PaymentTermsCreateForm) return;
        this.events();
    }

    events(){
        this.PaymentTermsCreateForm.addEventListener('submit', (event) => {
            event.preventDefault();
            BaseAjaxSave(event);
        });
    }

}